<template>
  <div>

    <div class="d-flex justify-end mb-4">
      <v-btn
        color="orange"
        class="text-none rounded-lg"
        dark
        elevation="0"
        small
        @click="exportData"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t("export") }}
      </v-btn>
    </div>

        <div class="piecharts" v-if="sentSeries">
          <div class="chartBloc">
            <doughnut-chart
              v-if="sentSeries"
              :chartData="sentSeries"
              :centerTxt="sentSeries.centerTxt"
              :centerTxtColor="sentSeries.centerTxtColor"
              title="$t('view.learning_phishing.emails_sent')"
            ></doughnut-chart>
          </div>

          <div class="chartBloc">
            <doughnut-chart
              v-if="openedSeries"
              :chartData="openedSeries"
              :centerTxt="openedSeries.centerTxt"
              :centerTxtColor="openedSeries.centerTxtColor"
              :title="openedSeries.title"
            ></doughnut-chart>
          </div>

          <div class="chartBloc">
            <doughnut-chart
              v-if="clickedSeries"
              :chartData="clickedSeries"
              :centerTxt="clickedSeries.centerTxt"
              :centerTxtColor="clickedSeries.centerTxtColor"
              :title="clickedSeries.title"
            ></doughnut-chart>
          </div>

          <div class="chartBloc">
            <doughnut-chart
              v-if="submittedSeries"
              :chartData="submittedSeries"
              :centerTxt="submittedSeries.centerTxt"
              :centerTxtColor="submittedSeries.centerTxtColor"
              :title="submittedSeries.title"
            ></doughnut-chart>
          </div>

          <div class="chartBloc">
            <doughnut-chart
              v-if="reportedSeries"
              :chartData="reportedSeries"
              :centerTxt="reportedSeries.centerTxt"
              :centerTxtColor="reportedSeries.centerTxtColor"
              :title="reportedSeries.title"
            ></doughnut-chart>
          </div>
        </div>

        <div v-if="studentResults">
          <v-data-table
            :headers="headers"
            :items="studentResults"
            :items-per-page="5"
            class="elevation-1"
          ></v-data-table>
        </div>

  </div>
</template>
<script>
import { i18n } from "@/plugins/i18n"

export default {
  name: "phishing-simulation-summary",
  props: {
    results: {
      default: null,
    },
  },
  data: () => ({
    localResults: null,
    studentResults: null,
    headers: [
      {
        text: i18n.t("First name"),
        align: "start",
        sortable: true,
        value: "first_name",
      },
      {
        text: i18n.t("Last name"),
        align: "start",
        sortable: true,
        value: "last_name",
      },
      {
        text: i18n.t("Email"),
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: i18n.t("Status"),
        align: "start",
        sortable: true,
        value: "status",
      },
      {
        text: i18n.t("Reported"),
        align: "start",
        sortable: true,
        value: "reported",
      },
    ],
    sentSeries: null,
    openedSeries: null,
    clickedSeries: null,
    submittedSeries: null,
    reportedSeries: null,
  }),
  mounted() {
    this.processSimulationResults(this.results)
  },
  watch: {
    results: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.processSimulationResults(newVal);
        }
      },
      deep: true, // This ensures we are watching for nested changes in the object
    },
  },
  methods:{
    processSimulationResults(results) {

      if (!results || !results.results) {
        console.error("no results.results")
        return;
      }

      const defaultGrey = "rgb(231, 230, 230)"
      const blue = "rgb(69, 115, 196)"
      const yellow = "rgb(250, 206, 48)"
      const red = "rgb(181, 0, 19)" // saisissent la donnée
      const grey = "rgb(120, 118, 118)" // ouvrent l'email
      const lightBlue = "rgb(121, 214, 236)" // reported

      if (results.stats) {
        const stats = results.stats

        this.sentSeries = {
          labels: [i18n.t("Emails sent"), i18n.t("Unsent emails")],
          title: i18n.t("Emails sent"),
          centerTxt: `${stats.sent}/${stats.total}`,
          centerTxtColor: "#4573c4",
          datasets: [
            {
              label: "label ?",
              data: [stats.sent, stats.total - stats.sent],
              backgroundColor: [blue, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
        this.openedSeries = {
          labels: [i18n.t("Open emails"), i18n.t("Unopened emails")],
          title: i18n.t("Open emails"),
          centerTxt: `${stats.opened}/${stats.total}`,
          centerTxtColor: "#787676",
          datasets: [
            {
              label: "label ?",
              data: [stats.opened, stats.total - stats.opened],
              backgroundColor: [grey, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
        this.clickedSeries = {
          labels: [i18n.t("Links clicked"), i18n.t("Unclicked links")],
          title: i18n.t("Links clicked"),
          centerTxt: `${stats.clicked}/${stats.total}`,
          centerTxtColor: "#face30",
          datasets: [
            {
              label: "label ?",
              data: [stats.clicked, stats.total - stats.clicked],
              backgroundColor: [yellow, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
        this.submittedSeries = {
          labels: [i18n.t("Data submitted"), i18n.t("Data not entered")],
          title: i18n.t("Data submitted"),
          centerTxt: `${stats.submitted_data}/${stats.total}`,
          centerTxtColor: "#b50013",
          datasets: [
            {
              label: "label ?",
              data: [stats.submitted_data, stats.total - stats.submitted_data],
              backgroundColor: [red, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
        this.reportedSeries = {
          labels: [i18n.t("Data reported"), i18n.t("Data not reported")],
          title: i18n.t("Data reported"),
          centerTxt: `${stats.email_reported}/${stats.total}`,
          centerTxtColor: "#000",
          datasets: [
            {
              label: "label ?",
              data: [stats.email_reported, stats.total - stats.email_reported],
              backgroundColor: [lightBlue, defaultGrey],
              hoverOffset: 4,
            },
          ],
        }
      }

      if (results.results.results) {
        this.studentResults = results.results.results
      }
    },
    exportData(){
      this.$emit("export")
    }
  }
}
</script>
<style lang="scss" scoped>
.piecharts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .chartBloc {
    max-width: 300px;
    width: 18%;

    h4 {
      text-align: center;
    }
  }
}
</style>

import Vue from "vue"

import {
  OpAlert,
  OpBrowserAlert,
  OpForm,
  OpLoginCard,
  OpTextField,
  OpDatePicker,
  OpCountrySelect,
  OpCyberscoreSummary,
  OpCyberscoreGauge,
  OpCyberscoreByTheme,
  OpCyberScoreByThemeBubble,
  OpBadge,
  OpEmployeeCyberscoreDialog,
  OpEmployeeList,
  OpEmployeeTableFilters
} from "./components"

import {
  ProgramPreset,
  ProgramPresetEditor,
} from "./components/program-presets"

Vue.component(ProgramPreset.name, ProgramPreset)
Vue.component(ProgramPresetEditor.name, ProgramPresetEditor)
Vue.component(OpAlert.name, OpAlert)
Vue.component(OpBrowserAlert.name, OpBrowserAlert)
Vue.component(OpForm.name, OpForm)
Vue.component(OpLoginCard.name, OpLoginCard)
Vue.component(OpTextField.name, OpTextField)
Vue.component(OpDatePicker.name, OpDatePicker)
Vue.component(OpCountrySelect.name, OpCountrySelect)
Vue.component(OpCyberscoreSummary.name, OpCyberscoreSummary)
Vue.component(OpCyberscoreGauge.name, OpCyberscoreGauge)
Vue.component(OpCyberscoreByTheme.name, OpCyberscoreByTheme)
Vue.component(OpCyberScoreByThemeBubble.name, OpCyberScoreByThemeBubble)
Vue.component(OpBadge.name, OpBadge)
Vue.component(OpEmployeeCyberscoreDialog.name, OpEmployeeCyberscoreDialog)
Vue.component(OpEmployeeList.name, OpEmployeeList)
Vue.component(OpEmployeeTableFilters.name, OpEmployeeTableFilters)

// Misc
import {
  OpModale,
  OpConfirmationDialog,
  OpSmartGroup,
  OpExercisePreviewModale,
  BatchCommandsLogsComponent,
  OpMultipleGroups,
  AssetItem,
  OpAutomationComponent,
  CompanyCyberScore,
  OpPreview,
  LocalizationFlags,
} from "./components/misc"
Vue.component(OpModale.name, OpModale)
Vue.component(OpConfirmationDialog.name, OpConfirmationDialog)
Vue.component(OpSmartGroup.name, OpSmartGroup)
Vue.component(OpExercisePreviewModale.name, OpExercisePreviewModale)
Vue.component(BatchCommandsLogsComponent.name, BatchCommandsLogsComponent)
Vue.component(OpMultipleGroups.name, OpMultipleGroups)
Vue.component(AssetItem.name, AssetItem)
Vue.component(OpAutomationComponent.name, OpAutomationComponent)
Vue.component(CompanyCyberScore.name, CompanyCyberScore)
Vue.component(OpPreview.name, OpPreview)
Vue.component(LocalizationFlags.name, LocalizationFlags)

//  Phishing
import {
  PhishingSimulationComponent,
  ProgramComponent,
  CampaignComponent,
  EmployeeGroupComponent,
  EmployeeComponent,
  PhishingExerciseComponent,
  ProgramEdition,
  CampaignEdition,
  PhishingSimulationEdition,
  PhishingTemplateEdition,
  EmployeeGroupEdition,
  SimulationResultsComponent,
  SimulationTimelineComponent,
  SimulationSummaryComponent,
  LandingPageComponent,
  ServerComponent,
  SendingProfileComponent,
  EmailTemplateComponent,
  PhishingEmailTemplateEdition,
  PhishingExerciseEdition,
  PhishingLandingPageEdition,
  PhishingServerGroupEdition,
  PhishingSendingProfileEdition,
  PhishingExerciseCatalog,
} from "./components/phishing"

Vue.component(PhishingSimulationComponent.name, PhishingSimulationComponent)
Vue.component(ProgramComponent.name, ProgramComponent)
Vue.component(CampaignComponent.name, CampaignComponent)
Vue.component(PhishingExerciseComponent.name, PhishingExerciseComponent)
Vue.component(ProgramEdition.name, ProgramEdition)
Vue.component(CampaignEdition.name, CampaignEdition)
Vue.component(PhishingSimulationEdition.name, PhishingSimulationEdition)
Vue.component(PhishingTemplateEdition.name, PhishingTemplateEdition)
Vue.component(SimulationResultsComponent.name, SimulationResultsComponent)
Vue.component(SimulationSummaryComponent.name, SimulationSummaryComponent)
Vue.component(SimulationTimelineComponent.name, SimulationTimelineComponent)
Vue.component(LandingPageComponent.name, LandingPageComponent)
Vue.component(ServerComponent.name, ServerComponent)
Vue.component(SendingProfileComponent.name, SendingProfileComponent)
Vue.component(EmailTemplateComponent.name, EmailTemplateComponent)
Vue.component(PhishingEmailTemplateEdition.name, PhishingEmailTemplateEdition)
Vue.component(PhishingExerciseEdition.name, PhishingExerciseEdition)
Vue.component(PhishingLandingPageEdition.name, PhishingLandingPageEdition)
Vue.component(PhishingServerGroupEdition.name, PhishingServerGroupEdition)
Vue.component(PhishingSendingProfileEdition.name, PhishingSendingProfileEdition)
Vue.component(EmployeeComponent.name, EmployeeComponent)
Vue.component(EmployeeGroupComponent.name, EmployeeGroupComponent)
Vue.component(EmployeeGroupEdition.name, EmployeeGroupEdition)
Vue.component(PhishingExerciseCatalog.name, PhishingExerciseCatalog)

//  Charts
import {
  DoughnutChart,
  BarChart,
  PieChart,
  RadialChart,
} from "./components/charts"
Vue.component(DoughnutChart.name, DoughnutChart)
Vue.component(BarChart.name, BarChart)
Vue.component(PieChart.name, PieChart)
Vue.component(RadialChart.name, RadialChart)

//  Companies
import CompanyEdition from "./views/company/ViewCompanyEdition.vue"
Vue.component(CompanyEdition.name, CompanyEdition)

import CompanyComponent from "./views/company/CompanyComponent.vue"
Vue.component(CompanyComponent.name, CompanyComponent)

// Oppens Learning
import {
  CompanyCourseView,
  CourseResultsView,
  CompanyCourseEditionView,
  OppensLearningResourceView,
  AssetEditionView,
  SlideEditionView,
  AnswerEditionView,
  CourseEditionView,
  HatCourseEditionView,
  HatcourseCatalog,
} from "./components/learning"

Vue.component(CompanyCourseView.name, CompanyCourseView)
Vue.component(CourseResultsView.name, CourseResultsView)
Vue.component(OppensLearningResourceView.name, OppensLearningResourceView)
Vue.component(CompanyCourseEditionView.name, CompanyCourseEditionView)
Vue.component(AssetEditionView.name, AssetEditionView)
Vue.component(SlideEditionView.name, SlideEditionView)
Vue.component(AnswerEditionView.name, AnswerEditionView)
Vue.component(CourseEditionView.name, CourseEditionView)
Vue.component(HatCourseEditionView.name, HatCourseEditionView)
Vue.component(HatcourseCatalog.name, HatcourseCatalog)

import {
  PerimeterComponent,
  RoleComponent,
  UserAccessControlComponent,
  UserAccessControlEditionComponent,
  PerimeterEditionComponent,
} from "./components/access-control"

Vue.component(PerimeterComponent.name, PerimeterComponent)
Vue.component(RoleComponent.name, RoleComponent)
Vue.component(UserAccessControlComponent.name, UserAccessControlComponent)
Vue.component(
  UserAccessControlEditionComponent.name,
  UserAccessControlEditionComponent
)
Vue.component(PerimeterEditionComponent.name, PerimeterEditionComponent)

// System

import {
  NotificationTemplate,
  NotificationTemplateEdition,
  EmailHeaderFooterEdition,
  EmailHeader,
  EmailFooter,
} from "./components/system"

Vue.component(NotificationTemplate.name, NotificationTemplate)
Vue.component(NotificationTemplateEdition.name, NotificationTemplateEdition)
Vue.component(EmailHeaderFooterEdition.name, EmailHeaderFooterEdition)
Vue.component(EmailHeader.name, EmailHeader)
Vue.component(EmailFooter.name, EmailFooter)

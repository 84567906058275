<template>
  <div v-if="localPerimeter">
    <v-card elevation="0" outlined color="#fafafa" class="ma-1">
      <v-card-text>
        <v-text-field
          :label="$t('view.companies.perimeter_name')"
          v-model="localPerimeter.name"
          class="required"
        >
        </v-text-field>

        <v-text-field
          :label="$t('view.companies.slug_perimeter')"
          v-model="localPerimeter.slug"
          class="required"
        >
        </v-text-field>

        <v-text-field
          :label="$t('view.companies.perimeter_description')"
          v-model="localPerimeter.description"
        >
        </v-text-field>

        {{ $t("Smart group") }}
        <v-switch v-model="localPerimeter.smart"></v-switch>

        <v-container v-if="localPerimeter.smart">
          <v-text-field
            :label="$t('view.companies.smart_sql_rules')"
            v-model="localPerimeter.smart_rules.sql"
          >
          </v-text-field>
        </v-container>

        <v-container v-else-if="selectedCompanies">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('view.companies.search')"
            single-line
            hide-details
            background-color="#def9f4"
            class="ma-2"
          ></v-text-field>

          <v-data-table
            v-model="selectedCompanies"
            :headers="headers"
            :items="sortedCompanies"
            :items-per-page="10"
            item-key="uuid"
            show-select
            class="elevation-1"
            :search="search"
            :footer-props="footerProps"
          >
          </v-data-table>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" small
        >{{ $t("view.button.save") }}
        </v-btn
        >
        <v-btn elevation="2" @click="close()" small>{{ $t("view.button.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {i18n} from "@/plugins/i18n";

export default {
  name: "oppens-perimeter-edition",

  props: {
    perimeter: {
      default: null,
    },
    companies: {
      default: null,
    },
  },

  data: function () {
    return {
      localPerimeter: null,
      selectedCompanies: null,
      search: "",
      footerProps: {
        "items-per-page-options": [10, 30, 50, 100, 200, -1],
      },
      headers: [
        {
          text: i18n.t('Name'),
          value: "business_name",
        },
        {
          text: i18n.t('Description'),
          value: "trade_name",
        },
      ],
    }
  },

  async mounted() {
    let localPerimeter = JSON.parse(JSON.stringify(this.perimeter))

    if (!localPerimeter.smart) {
      this.selectedCompanies = localPerimeter.companies
        ? localPerimeter.companies
        : []
    } else {
      this.selectedCompanies = []
    }

    if (!localPerimeter.smart_rules) {
      localPerimeter.smart_rules = {
        sql: "",
      }
    } else {
      localPerimeter.smart_rules = JSON.parse(localPerimeter.smart_rules)
    }

    localPerimeter.companies_uuid = this.perimeter.companies.map(
      (company) => company.uuid
    )

    this.localPerimeter = localPerimeter
  },
  computed: {
    form() {
      return this.$refs.form
    },

    sortedCompanies() {
      if (!this.companies || !this.selectedCompanies) {
        return []
      }

      const selectedUuids = new Set(this.selectedCompanies.map((c) => c.uuid))
      return [...this.companies].sort((a, b) => {
        const aSelected = selectedUuids.has(a.uuid)
        const bSelected = selectedUuids.has(b.uuid)
        return bSelected - aSelected // ensure selected items are at the top
      })
    },
  },

  methods: {
    submit() {
      if ("" === this.localPerimeter.slug || "" === this.localPerimeter.name) {
        return
      }

      this.localPerimeter.companies_uuid = this.selectedCompanies.map(
        (company) => company.uuid
      )
      this.$emit("save", this.localPerimeter)
    },

    close() {
      this.$emit("close")
    },
  },
}
</script>

import { render, staticRenderFns } from "./CampaignComponent.vue?vue&type=template&id=6597681c&scoped=true&"
import script from "./CampaignComponent.vue?vue&type=script&lang=js&"
export * from "./CampaignComponent.vue?vue&type=script&lang=js&"
import style0 from "./CampaignComponent.vue?vue&type=style&index=0&id=6597681c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6597681c",
  null
  
)

export default component.exports
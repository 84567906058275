<template>
  <v-container fluid style="width: 100%; height: 100%">
      <div class="text-h5 mb-5" v-if="!displayCompanyItem">{{$t("view.companies.company_edition")}}</div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>
    <div v-else>
      <v-card v-if="localData" class="mt-5" outlined>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="2" @click="submit()" dark color="green" small>{{
              $t("view.button.save")
            }}
          </v-btn>
          <v-btn elevation="2" @click="close()" small v-if="displayCompanyItem">{{
              $t("view.button.close")
            }}
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <EditCompanyGeneral :data="localData" ref="editGeneral" />
          <EditCompanyAzureAD :data="localData" ref="editAzureAD" />
          <EditCompanyDomains :data="localData" ref="editDomains" v-if="isSystemAdmin" />
          <EditCompanySSO :data="localData" ref="editSSO" />
          <!--EditCompanyCustomization :data="localData" ref="editCustomization" /-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="2" @click="submit()" dark color="green" small>{{
              $t("view.button.save")
            }}
          </v-btn>
          <v-btn elevation="2" @click="close()" small v-if="displayCompanyItem">{{
              $t("view.button.close")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <h1>* No local data</h1>
        <pre>{{ localData }}</pre>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import EditCompanyGeneral from "../../components/company-edition/EditCompanyGeneral.vue"
import EditCompanyAzureAD from "../../components/company-edition/EditCompanyAzureAD.vue"
import EditCompanyDomains from "../../components/company-edition/EditCompanyDomains.vue"
import EditCompanySSO from "../../components/company-edition/EditCompanySSO.vue"
import EditCompanyCustomization from "../../components/company-edition/EditCompanyCustomization.vue"
import CompanyLogic from "@/logics/CompanyLogic"
import companiesMixin from "@/mixins/companies.mixins.js"
import { showSnackbar } from "@/services/GlobalActions"
import { i18n } from "@/plugins/i18n"
import AuthStore from "@/stores/AuthStore"

export default {
  mixins: [companiesMixin],
  name: "company-edition",
  components: {
    EditCompanyGeneral,
    EditCompanyDomains,
    EditCompanySSO,
    EditCompanyAzureAD,
    //EditCompanyCustomization,
  },
  props: {
    data: {
      default: null,
    },
    displayCompanyItem: {
      default: true,
    },
  },
  data: function() {
    return {
      loading: true,
      localData: null,
      isSystemAdmin: false,
      companyUuid: null,
    }
  },
  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin")

    if (this.$route.params.companyUuid) {

      this.companyUuid = this.$route.params.companyUuid
      this.company = await CompanyLogic.getOne(this.companyUuid, { with_active_directory_credentials: 1 })
      this.localData = JSON.parse(JSON.stringify(this.company))
    } else {
      this.localData = JSON.parse(JSON.stringify(this.data))
    }
    this.loading = false
  },
  methods: {
    async submit() {
      let isValid = true

      for (let componentRef in this.$refs) {
        // Validate the forms in each component
        isValid = isValid && await this.$refs[componentRef].validate()
      }

      if (!isValid) {
        console.error("Validation failed")
        return
      }

      let companyData = this.localData
      for (let componentRef in this.$refs) {
        // GET the values from all the components
        companyData = Object.assign(companyData, this.$refs[componentRef].getCompanyData())
      }

      //   sconsole.log("Valid Company data", companyData)
      if (this.$route.params.companyUuid) {
        const company = await this.saveCompany(this.localData)

        if (company) {
          showSnackbar(i18n.t("view.companies.edition_save"))
          this.$router.push(`/company-management/${this.company.uuid}/programs`)
          this.$emit("update-company", company)
        }
      } else {
        this.$emit("save", companyData)
      }
    },
    close() {
      if (this.$route.params.companyUuid) {
        this.$router.push(`/company-management/${this.company.uuid}/programs`)
      } else {
        this.$emit("close")
      }
    },
  },
}
</script>

<style scoped>
</style>

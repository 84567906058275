<template>
  <div v-if="data">
    <v-card elevation="0" outlined class="ma-1">
      <v-card-title>
        <div>
          <v-chip small label dark color="#9c27b0" class="mr-2">
            {{ data.id }}
          </v-chip>

          <span v-if="data.title">{{ data.title }} </span>
          <span v-else> {{ type }} {{ data.id }} </span>
        </div>
      </v-card-title>

      <op-modale
        :display="showHTML"
        :data="html"
        v-on:closed="closedPreview"
      ></op-modale>

      <v-card-text v-if="'assets' == type">
        <pre>{{ data }}</pre>
      </v-card-text>

      <v-card-text v-else-if="'slides' == type">
        <!-- if data.course render data.course.title else orphan -->
        <div v-if="data.course">
          <b>{{ $t("view.learning_phishing.course") }} :{{ data.course.title }}</b>
        </div>

        <div v-else>
          <b>{{ $t("view.learning_phishing.course") }} :</b> <span style="color: red">{{
            $t("view.companies.orphan")
          }}</span>
        </div>

        <div><b>{{ $t("view.learning_phishing.description") }} :</b> {{ data.description }}</div>
        <div><b>{{ $t("view.learning_phishing.language") }} :</b> {{ data.lang ? data.lang : null }}</div>
        <div><b>{{ $t("view.learning_phishing.feedback") }} :</b> {{ data.feedback }}</div>
        <div v-if="data.feedback_long">
          <b>{{ $t("view.learning_phishing.feedback_long") }} :</b>
          <span class="html-code" v-html="data.feedback_long"></span>
        </div>

        <div v-if="data.illustration">
          <b>{{ $t("view.learning_phishing.illustration") }} :</b>
          <v-img
            max-height="150"
            max-width="250"
            :src="data.illustration"
          ></v-img>
        </div>
        <div v-if="data.html_illustration">
          <b>{{ $t("view.learning_phishing.html_illustration") }} : </b>

          <v-btn
            elevation="2"
            @click="previewHtml(data.html_illustration)"
            dark
            color="orange"
            small
          >{{ $t("view.button.preview") }}
          </v-btn>
        </div>

        <div style="margin-top: 40px">
          <h3>{{ $t("view.learning_phishing.answers") }}</h3>
          <div class="answers" :key="answer.id" v-for="answer in data.answers">
            <v-chip small label dark color="blue">
              {{ answer.id }}
            </v-chip>
            <div><b>{{ $t("view.learning_phishing.description") }} :</b> {{ answer.description }}</div>
            <div><b>{{ $t("view.learning_phishing.score") }} :</b> {{ answer.score }}</div>
            <div><b>{{ $t("view.learning_phishing.language") }} :</b> {{ answer.lang }}</div>
          </div>
        </div>
      </v-card-text>

      <v-card-text v-else-if="'courses' == type">
        <div v-if="data.title"><b>{{ $t("view.learning_phishing.title") }} :</b> {{ data.title }}</div>

        <div v-if="data.description">
          <b>{{ $t("view.learning_phishing.description") }} :</b> {{ data.description }}
        </div>

        <div v-if="data.lang"><b>{{ $t("view.learning_phishing.language") }} :</b> {{ data.lang }}</div>

        <div v-if="data.created_at">
          <b>{{ $t("view.learning_phishing.creation") }} :</b> {{ formatDate(data.created_at) }}
        </div>

        <div v-if="data.updated_at">
          <b>{{ $t("view.learning_phishing.update") }} :</b> {{ formatDate(data.updated_at) }}
        </div>

        <div v-if="data.hat_course_id">
          <b>{{ $t("view.learning_phishing.hat_course_id") }} :</b>

          <v-chip class="ml-5" small label dark color="blue">
            {{ data.hat_course_id }}
          </v-chip>

          <v-carousel v-if="data.slides.length > 0" hide-delimiters>
            <v-carousel-item v-for="slide in data.slides" :key="slide.id">
              <v-sheet height="100%" tile>
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="6" class="pl-12">
                    <div>
                      <v-chip small label dark color="light-blue">
                        {{ slide.id }}
                      </v-chip>
                    </div>
                    <div>{{ slide.title }}</div>
                    <div>{{ slide.description }}</div>
                    <div>{{ slide.feedback }}</div>
                    <div v-if="slide.feedback_long">
                      <span
                        class="html-code"
                        v-html="slide.feedback_long"
                      ></span>
                    </div>
                  </v-col>
                  <v-col cols="6" v-if="slide.illustration">
                    <v-img
                      max-height="150"
                      max-width="250"
                      :src="slide.illustration"
                    ></v-img>
                  </v-col>
                  <v-col cols="6" v-if="slide.html_illustration">
                    <span v-html="slide.html_illustration"></span>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-card-text>

      <v-card-text v-else>
        <div v-if="data.title"><b>{{ $t("view.learning_phishing.title") }} :</b> {{ data.title }}</div>
        <div v-if="data.subtitle">
          <b>{{ $t("view.learning_phishing.key_info") }} :</b> {{ data.subtitle }}
        </div>
        <div v-if="data.description">
          <b>{{ $t("view.learning_phishing.description") }} :</b> {{ data.description }}
        </div>
        <div v-if="data.lang"><b>{{ $t("view.learning_phishing.language") }} :</b> {{ data.lang }}</div>
        <div v-if="data.score"><b>{{ $t("view.learning_phishing.score") }} :</b> {{ data.score }}</div>
        <div v-if="data.duration"><b>{{ $t("view.learning_phishing.duration") }} :</b> {{ data.duration }} min</div>
        <div v-if="data.created_at">
          <b>{{ $t("view.learning_phishing.date") }} :</b> {{ formatDate(data.created_at) }}
        </div>
        <div v-if="data.updated_at">
          <b>{{ $t("view.learning_phishing.update") }} :</b> {{ formatDate(data.updated_at) }}
        </div>
        <div v-if="data.image">
          <b>{{ $t("view.learning_phishing.image") }} :</b>
          <v-img max-height="150" max-width="250" :src="data.image"></v-img>
        </div>

        <div v-if="data.stimuli">
          <b>{{ $t("view.learning_phishing.stimuli") }} :</b>
          <v-chip
            small
            label
            dark
            color="light-blue"
            v-for="stimulus in data.stimuli"
            v-bind:key="stimulus.id"
            class="mr-2"
          >
            {{ stimulus.slug }}
          </v-chip>
        </div>

        <div v-if="data.thematics">
          <b>{{ $t("view.learning_phishing.themes") }} :</b>
          <v-chip
            small
            label
            dark
            color="orange"
            v-for="thematic in data.thematics"
            v-bind:key="thematic.id"
            class="mr-2"
          >
            {{ thematic.slug }}
          </v-chip>
        </div>
      </v-card-text>

      <!-- Sort Slide Popup-->
      <template v-if="showDialog">
        <v-dialog v-model="showDialog">
          <v-card class="pa-7">
            <v-card
              v-for="(slide, index) in data.slides"
              :key="index"
              class="ma-7 pa-7 slideCard"
              draggable="true"
              @dragstart.self="dragStart(index, $event)"
              @dragenter.self="dragEnter($event)"
              @dragover.self="dragOver($event)"
              @dragleave.self="dragLeave($event)"
              @drop.self="drop(index, $event)"
              @dragend.self="dragEnd($event)"
            >
              <v-row>
                <v-col>
                  <div class="text-h6 font-weight-bold">
                    <v-chip small label dark color="light-blue" class="mr-2">
                      {{ slide.id }}
                    </v-chip>
                    {{ slide.title }}
                  </div>
                  <div>{{ slide.description }}</div>
                  <div>{{ slide.feedback }}</div>
                  <div v-if="slide.feedback_long">
                    <span class="html-code" v-html="slide.feedback_long"></span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <v-row class="ma-4">
              <v-spacer></v-spacer>
              <v-btn elevation="2" small class="mr-4" @click="closeDialog"
              >{{ $t("view.button.cancel") }}
              </v-btn
              >
              <v-btn elevation="2" dark color="green" @click="saveOrder" small
              >{{ $t("view.button.save") }}
              </v-btn
              >
            </v-row>
          </v-card>
        </v-dialog>
      </template>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="'courses' == type"
          color="light-blue"
          dark
          elevation="2"
          small
          @click="openDialog"
        >
          {{ $t("view.learning_phishing.sort_slides") }}
        </v-btn>
        <v-btn
          v-if="'courses' == type"
          color="blue"
          dark
          elevation="2"
          small
          @click="$emit('generate-scorm', data)"
        >{{ $t("view.learning_phishing.download_scorm") }}
        </v-btn
        >
        <v-btn elevation="2" small @click="duplicate()">{{ $t("view.button.duplicate") }}</v-btn>
        <v-btn color="green" dark elevation="2" small @click="edit()"
        >{{ $t("view.button.edit") }}
        </v-btn
        >
        <v-icon @click="suppress()" class="ml-2" elevation="2" icon
        >mdi-delete
        </v-icon>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import moment from "moment"

export default {
  name: "oppens-learning-resource",
  props: {
    type: {
      default: null,
    },
    data: {
      default: null,
    },
    courses: {
      default: null,
    },
  },
  data: () => ({
    showHTML: null,
    html: null,
    showDialog: false,
  }),
  async created() {
    // TODO this is a temporary fix : not sure the 'courses' param should not really be there
    if (this.courses) {
      this.courses.map((course) => {
        let slideInCourse = course.slides.find(
          (slideInCourse) => slideInCourse.id === this.data.id
        )
        if (slideInCourse) {
          this.data.course = course
        }
      })
    }
  },
  methods: {
    previewHtml(html) {
      this.html = html
      this.showHTML = true
    },
    closedPreview() {
      this.html = null
      this.showHTML = false
    },
    async suppress() {
      this.$emit("suppress", this.data)
    },
    edit() {
      this.$emit("edit", this.data)
    },
    formatDate(str) {
      return moment(str).format("MMMM D YYYY, h:mm")
    },
    duplicate() {
      this.$emit("duplicate", this.data)
    },
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    saveOrder() {
      this.$emit("moveSlides", this.data)
      this.closeDialog()
    },
    //draggable behavior
    dragStart(index, event) {
      event.dataTransfer.setData("text/plain", index)
    },

    dragEnter(event) {
      event.target.classList.add("drag-enter")
    },

    dragOver(event) {
      event.preventDefault()
    },

    dragLeave(event) {
      event.target.classList.remove("drag-enter")
    },

    drop(toIndex, event) {
      const fromIndex = event.dataTransfer.getData("text/plain")
      const itemToMove = this.data.slides[fromIndex]
      this.data.slides.splice(fromIndex, 1)
      this.data.slides.splice(toIndex, 0, itemToMove)
      event.target.classList.remove("drag-enter")
    },
  },
}
</script>
<style lang="scss" scoped>
.html-code {
  display: block;
  border-left: 2px dashed #cfcfcf;
  padding: 10px 40px;
  margin: 10px 0 10px 0;
}

.answers {
  margin: 10px;
  padding: 10px;
  border: 1px dashed #a1a3a4;
  display: inline-block;
  border-radius: 10px;
  min-height: 120px;
}

.drag-enter {
  border: 2px dashed #000;
}

.slideCard * {
  pointer-events: none;
}
</style>

<template>
  <div v-if="localCampaign">
    <v-card elevation="0" outlined color="#fafafa" class="ma-1">
      <v-card-text>
        <v-text-field
          :label="$t('view.companies.campaign_name')"
          v-model="localCampaign.name"
          class="required"
        >
        </v-text-field>

        <v-text-field
          :label="$t('view.companies.campaign_description')"
          v-model="localCampaign.description"
        >
        </v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" small
        >{{ $t("view.button.save") }}
        </v-btn
        >
        <v-btn elevation="2" @click="close()" small>{{ $t("view.button.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "oppens-campaign-edition",
  props: {
    campaign: {
      default: null,
    },
  },
  data: function () {
    return {
      localCampaign: null,
    }
  },
  async mounted() {
    this.localCampaign = JSON.parse(JSON.stringify(this.campaign))
  },
  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
    form() {
      return this.$refs.form
    },
  },
  methods: {
    submit() {
      this.$emit("save", this.localCampaign)
    },
    close() {
      this.$emit("close")
    },
    updateStart(data) {
      this.localCampaign.start_date = data
    },
    updateEnd(data) {
      this.localCampaign.end_date = data
    },
  },
}
</script>

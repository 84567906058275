var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:({ backgroundColor: '#ffffff' })},[(_vm.localData && _vm.localData.active_directory_credentials)?_c('div',{staticClass:"flex-container"},[_c('div',{staticClass:"bloc"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"bloc-title"},[_vm._v(" "+_vm._s(_vm.$t( "profile.active_directory_credentials.azure_ad_sync_parameters", ))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-text',{staticClass:"px-3 pa-0"},[_c('op-text-field',{attrs:{"name":"clientId","color":"green","label":_vm.$t('profile.active_directory_credentials.client_id')},model:{value:(_vm.localData.active_directory_credentials.client_id),callback:function ($$v) {_vm.$set(_vm.localData.active_directory_credentials, "client_id", $$v)},expression:"localData.active_directory_credentials.client_id"}})],1),_c('v-card-text',{staticClass:"px-3 pa-0"},[_c('op-text-field',{attrs:{"name":"tenantId","color":"green","label":_vm.$t('profile.active_directory_credentials.tenant_id')},model:{value:(_vm.localData.active_directory_credentials.tenant_id),callback:function ($$v) {_vm.$set(_vm.localData.active_directory_credentials, "tenant_id", $$v)},expression:"localData.active_directory_credentials.tenant_id"}})],1),_c('v-card-text',{staticClass:"px-3 pa-0"},[_c('op-text-field',{attrs:{"color":"green","name":"clientSecret","label":_vm.$t('profile.active_directory_credentials.client_secret')},model:{value:(
                    _vm.localData.active_directory_credentials.client_secret
                  ),callback:function ($$v) {_vm.$set(_vm.localData.active_directory_credentials, "client_secret", $$v)},expression:"\n                    localData.active_directory_credentials.client_secret\n                  "}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("profile.active_directory_credentials.managing_user_sync"))+" "),_c('v-switch',{attrs:{"flat":"","label":_vm.$t('profile.active_directory_credentials.activate_sync')},model:{value:(_vm.syncUsersAzureAd),callback:function ($$v) {_vm.syncUsersAzureAd=$$v},expression:"syncUsersAzureAd"}})],1)],1)],1),_c('div',{staticClass:"bloc"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"bloc-title"},[_vm._v(" "+_vm._s(_vm.$t( "profile.active_directory_credentials.filter_azure_ad_results", ))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-text',{staticClass:"px-3 pa-0"},[_c('op-text-field',{attrs:{"name":"filters_domains","type":"text","rules":"","color":"green","label":_vm.$t(
                      'The domain names to be included (separated by a semicolon)'
                    )},model:{value:(
                    _vm.localData.active_directory_credentials.filters.domains
                  ),callback:function ($$v) {_vm.$set(_vm.localData.active_directory_credentials.filters, "domains", $$v)},expression:"\n                    localData.active_directory_credentials.filters.domains\n                  "}})],1),_c('v-card-text',{staticClass:"px-3 pa-0"},[_c('op-text-field',{attrs:{"name":"filters_countries","type":"text","rules":"","color":"green","label":_vm.$t(
                      'The country names to be included (separated by a semicolon)'
                    )},model:{value:(
                    _vm.localData.active_directory_credentials.filters.countries
                  ),callback:function ($$v) {_vm.$set(_vm.localData.active_directory_credentials.filters, "countries", $$v)},expression:"\n                    localData.active_directory_credentials.filters.countries\n                  "}})],1),_c('v-card-text',{staticClass:"px-3 pa-0"},[_c('op-text-field',{attrs:{"name":"filters_ignore_words_display_name","type":"text","rules":"","color":"green","label":_vm.$t(
                      'Words to exclude from the displayName field (separated by a semicolon)'
                    )},model:{value:(
                    _vm.localData.active_directory_credentials.filters.ignoreWords
                      .displayName
                  ),callback:function ($$v) {_vm.$set(_vm.localData.active_directory_credentials.filters.ignoreWords
                      , "displayName", $$v)},expression:"\n                    localData.active_directory_credentials.filters.ignoreWords\n                      .displayName\n                  "}})],1),_c('v-card-text',{staticClass:"px-3 pa-0"},[_c('op-text-field',{attrs:{"name":"filters_ignore_words_given_name","type":"text","rules":"","color":"green","label":_vm.$t(
                      'Words to exclude from the givenName field (separated by a semicolon)'
                    )},model:{value:(
                    _vm.localData.active_directory_credentials.filters.ignoreWords
                      .givenName
                  ),callback:function ($$v) {_vm.$set(_vm.localData.active_directory_credentials.filters.ignoreWords
                      , "givenName", $$v)},expression:"\n                    localData.active_directory_credentials.filters.ignoreWords\n                      .givenName\n                  "}})],1),_c('v-card-text',{staticClass:"px-3 pa-0"},[_c('op-text-field',{attrs:{"name":"surname","type":"text","rules":"","color":"green","label":_vm.$t(
                      'Words to exclude from the surname field (separated by a semicolon)'
                    )},model:{value:(
                    _vm.localData.active_directory_credentials.filters.ignoreWords
                      .surname
                  ),callback:function ($$v) {_vm.$set(_vm.localData.active_directory_credentials.filters.ignoreWords
                      , "surname", $$v)},expression:"\n                    localData.active_directory_credentials.filters.ignoreWords\n                      .surname\n                  "}})],1),_c('div',{staticClass:"px-3 pa-0"},[_c('v-btn',{attrs:{"elevation":"2","dark":"","color":!_vm.localData.active_directory_credentials.client_id ||
                    !_vm.localData.active_directory_credentials.tenant_id ||
                    !_vm.localData.active_directory_credentials.client_secret
                      ? 'grey'
                      : 'green',"small":""},on:{"click":function($event){$event.stopPropagation();!_vm.localData.active_directory_credentials.client_id ||
                    !_vm.localData.active_directory_credentials.tenant_id ||
                    !_vm.localData.active_directory_credentials.client_secret
                      ? null
                      : _vm.testImportUsersAzure()}}},[_vm._v(" "+_vm._s(_vm.$t("view.button.test_azure_filter"))+" ")])],1)],1)],1)],1)]):_vm._e(),_c('v-card-text',[(_vm.loadingEmployeesAzure)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"yellow darken-2"}}):_vm._e(),(_vm.listEmployeesAzure)?_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"bloc-large"},[_c('div',{staticClass:"bloc-title"},[_c('b',[_vm._v(" "+_vm._s(_vm.listEmployeesAzure.length)+" "+_vm._s(_vm.$t("profile.active_directory_credentials.users_from_directory"))+" ")])]),_c('v-card',{staticClass:"ma-3"},[_c('v-text-field',{staticClass:"ma-2",attrs:{"append-icon":"mdi-magnify","label":"  Recherche","single-line":"","hide-details":"","background-color":"#def9f4"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listEmployeesAzure,"items-per-page":10,"search":_vm.search,"footer-props":_vm.footers,"item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("Aucun employé")]},proxy:true}],null,false,2543962150)})],1)],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
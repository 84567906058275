<template>
  <v-container class="pa-0">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>

    <op-modale
      :display="show"
      :data="html"
      v-on:closed="closedPreview"
    ></op-modale>

    <v-card elevation="0" outlined color="white">
      <v-card-text v-if="localCourse && hatCourses">
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$t('view.learning_phishing.learning_name')"
              v-model="localCourse.name"
            >
            </v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              :label="$t('view.learning_phishing.description')"
              v-model="localCourse.description"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedHatCourse"
              :items="hatCourses"
              item-text="title"
              return-object
              filled
              readonly
              :label="$t('view.learning_phishing.learning_selection')"
              class="required pt-2 pb-2"
              :disabled="localCourse.is_active"
              @click.native="openModal"
            ></v-select>
          </v-col>

          <v-dialog v-model="showModalCours" max-width="80%">
            <v-card>
              <v-card-title>
                <div style="width: 100%; display: inline-block">
                  {{ $t("view.learning_phishing.select_course") }}
                </div>
                <div style="width: 100%; display: inline-block">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('view.companies.search')"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <div class="d-flex flex-wrap justify-space-around">
                    <div
                      v-for="course in filteredCourses"
                      :key="course.id"
                      class="ma-1"
                      style="width: 23em"
                    >
                      <v-card min-width="250" @click="selectCourse(course)">
                        <v-img
                          :src="
                            course.image
                              ? course.image
                              : require('@/assets/exercice-image.png')
                          "
                          aspect-ratio="1"
                          max-height="150"
                        ></v-img>

                        <div class="title-card">
                          {{ course.title }}
                        </div>
                        <v-divider class="mx-4"></v-divider>
                        <div class="subtitle">
                          <span class="label"
                            >{{
                              $t("view.learning_phishing.description")
                            }}:</span
                          >
                          {{ truncateDescription(course.description) }}
                        </div>
                        <div class="subtitle">
                          <span class="label"
                            >{{ $t("view.learning_phishing.duration") }}:
                          </span>
                          {{ course.duration }}
                        </div>
                      </v-card>
                    </div>
                  </div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="showModalCours = false"
                  >Fermer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-col cols="6">
            <v-select
              v-model="selectedEmployeeGroups"
              :items="employeeGroups"
              multiple
              chips
              item-text="name"
              filled
              :label="$t('view.companies.employees_group_selection')"
              return-object
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedCampaign"
              :items="campaignsWithNull"
              item-text="name"
              filled
              :label="$t('view.companies.campaign_selection')"
              return-object
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-select
              v-model="localCourse.invitation_email_type"
              :items="availableEmailTypes"
              item-text="name"
              item-value="type"
              filled
              :label="$t('view.companies.email_type_invitation')"
              @change="onSelectInvitation"
            ></v-select>
          </v-col>
          <v-col
            cols="1"
            class="d-flex justify-center"
            style="
              flex-direction: column-reverse;
              padding: 0;
              padding-right: 12px;
            "
          >
            <op-preview-button
              v-model="selectedInvitationLang"
              :options="invitationSelect"
              @update="onInvitationEmailChange($event)"
            />
          </v-col>

          <v-col cols="5">
            <v-select
              v-model="localCourse.reminder_email_type"
              :items="availableEmailTypes"
              item-text="name"
              item-value="type"
              filled
              @change="onSelectReminder"
              :label="$t('view.companies.type_of_reminder_email')"
            ></v-select>
          </v-col>
          <v-col
            cols="1"
            class="d-flex justify-center"
            style="
              flex-direction: column-reverse;
              padding: 0;
              padding-right: 12px;
            "
          >
            <op-preview-button
              v-model="selectedReminderLang"
              :options="reminderSelect"
              @update="onReminderEmailChange($event)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="flex-item">
              <v-switch
                v-model="localCourse.is_mandatory"
                :label="$t('view.companies.mandatory_learning')"
              ></v-switch>
            </div>
          </v-col>
        </v-row>

        <div style="font-size: 18px; padding: 0 8px" class="mt-8">
          <v-icon class="mr-2">mdi-settings</v-icon>
          {{ $t("view.companies.automation") }}
        </div>

        <v-divider class="mt-2"></v-divider>

        <v-row class="mt-4 ms-6 me-6">
          <v-col cols="12" sm="6" md="4" lg="3" v-if="isSystemAdmin">
            <v-switch
              v-model="localCourse.is_live"
              :label="$t('view.companies.mode_live')"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_invites"
              :label="$t('view.companies.automatic_invitation')"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_launch"
              :label="$t('view.companies.automatic_launch')"
              v-on:change="resetDate"
              :disabled="localCourse.is_active"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <op-datepicker
              :label="$t('view.companies.launch_date')"
              :date="localCourse.start_date"
              v-on:update="updateStartDate"
              :readonly="
                !localCourse.has_automatic_launch || localCourse.is_active
              "
              :required="localCourse.has_automatic_launch"
              :style="{
                pointerEvents: localCourse.has_automatic_launch
                  ? 'auto'
                  : 'none',
              }"
            >
            </op-datepicker>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_reminders"
              :label="$t('view.companies.automatic_reminders')"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_report"
              :label="$t('view.companies.automatic_report')"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_stop"
              :label="$t('view.companies.automatic_stop')"
              @change="resetDate"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <op-datepicker
              :label="'Date d\'arrêt'"
              :date="calculateStopDate(localCourse.start_date)"
              readonly
              :style="{
                pointerEvents: 'none',
              }"
            ></op-datepicker>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_employee_group_refresh"
              :label="$t('view.companies.automatic_employee_group_refresh')"
            >
              <template v-slot:label>
                <div class="d-flex align-center">
                  <span>{{ $t('view.companies.automatic_employee_group_refresh') }}</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        color="grey"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('view.companies.hint_daily_update') }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-switch>
          </v-col>


          <!--v-col cols="12" sm="6" md="4" lg="3">
            <v-switch
              v-model="localCourse.has_automatic_employee_group_refresh"
              :label="$t('view.companies.automatic_employee_group_refresh')"
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  color="primary"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>{{ $t('view.companies.hint_daily_update') }}</span>
            </v-tooltip>
            </v-switch>
          </v-col-->
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" small
          >{{ $t("view.button.save") }}
        </v-btn>
        <v-btn elevation="2" @click="close()" small>{{
          $t("view.button.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import AuthStore from "@/stores/AuthStore"
import programsMixin from "@/mixins/programs.mixin"
import employeesMixin from "@/mixins/employees.mixin"
import learningMixin from "@/mixins/learning.mixin"
import campaignsMixin from "@/mixins/campaigns.mixin"
import emailsMixin from "@/mixins/emails.mixin"
import { i18n } from "@/plugins/i18n"
import LocaleLogic from "../../../logics/LocaleLogic"

export default {
  mixins: [
    programsMixin,
    employeesMixin,
    learningMixin,
    campaignsMixin,
    emailsMixin,
  ],
  name: "learning-company-course-edition",
  props: {
    course: {
      default: null,
    },
    campaignId: {
      default: null,
    },
  },
  data: function () {
    return {
      isSystemAdmin: false,
      localCourse: null,
      selectedProgram: null,
      selectedCampaign: null,
      selectedEmployeeGroups: null,
      selectedHatCourse: null,
      campaignsWithNull: null,
      availableEmailTypes: null,
      programs: null,
      campaigns: null,
      hatCourses: [],
      invitation_email: null,
      reminder_email: null,
      show: false,
      html: null,
      reminder_index: null,
      reminderSelect: [],
      invitationSelect: [],
      selectedInvitationLang: "",
      selectedReminderLang: "",
      snackbar: false,
      snackbarText: null,
      showModalCours: false,
      search: "",
    }
  },
  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin")
    this.programs = await this.getAllPrograms()
    this.campaigns = await this.getAllCampaigns()

    if (this.campaignId) {
      this.selectedCampaign = this.campaigns.find(
        (c) => c.id === this.campaignId
      )
      if (this.selectedCampaign) {
        this.selectedProgram = this.programs.find(
          (p) => p.id === this.selectedCampaign.program_id
        )
      }
    }

    this.campaignsWithNull = [{ name: " - ", id: null }].concat(this.campaigns) // add a null campaign to the list
    this.availableEmailTypes = await this.getAvailableEmailTypes(this.getLocale(LocaleLogic.getBrowserLanguage()))
    this.hatCourses = await this.getAvailableHatCourses()
    this.employeeGroups = await this.getAllEmployeeGroups()
    this.localCourse = JSON.parse(JSON.stringify(this.course))
    this.reminder_index = this.availableEmailTypes.findIndex(
      (et) => et.type === this.localCourse.reminder_email_type
    )
    this.reminderSelect = this.availableEmailTypes[this.reminder_index].langs
    this.findInvitationEmailIndex()
    this.invitationSelect =
      this.availableEmailTypes[this.invitation_index].langs
    this.selectedInvitationLang = this.invitationSelect.includes("fr")
      ? "fr"
      : this.invitationSelect[0]
    this.selectedReminderLang = this.reminderSelect.includes("fr")
      ? "fr"
      : this.reminderSelect[0]

    this.initProgramAndGroups()

    this.$emit("loaded")
  },

  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
    form() {
      return this.$refs.form
    },
    filteredCourses() {
      return this.hatCourses
        .filter((course) => {
          return (
            course.title.toLowerCase().includes(this.search.toLowerCase()) ||
            (course.description &&
              course.description
                .toLowerCase()
                .includes(this.search.toLowerCase()))
          )
        })
        .sort((a, b) => a.title.localeCompare(b.title))
    },
  },
  methods: {
    openModal() {
      this.showModalCours = true
    },
    getLocale(locale) {
      if (LocaleLogic.availableLocales().includes(locale)) {
        return locale;
      } else {
        return "en";
      }
    },
    truncateDescription(description) {
      const words = description.split(" ")
      if (words.length > 4) {
        return words.slice(0, 4).join(" ") + "..."
      }
      return description
    },
    calculateStopDate(date) {
      const startDateObj = new Date(date)

      const stopDate = new Date(
        startDateObj.getTime() + 60 * 24 * 60 * 60 * 1000
      )
      if (this.localCourse.has_automatic_stop) {
        return stopDate.toISOString().split("T")[0]
      } else {
        return ""
      }
    },
    selectCourse(course) {
      if (course && course.id) {
        this.localCourse.hat_course_id = course.id
        this.selectedHatCourse = course
      }
      this.showModalCours = false
    },

    async onSelectInvitation() {
      this.findInvitationEmailIndex()
      this.invitationSelect =
        this.availableEmailTypes[this.invitation_index].langs
    },
    async onSelectReminder() {
      this.findReminderEmailIndex()
      this.reminderSelect = this.availableEmailTypes[this.reminder_index].langs
    },
    async onInvitationEmailChange(value) {
      if (!this.selectedHatCourse) {
        this.results = null
        this.snackbar = true
        this.snackbarText = i18n.t("view.snackbar.select_hatcourse")
        return
      }
      this.selectedInvitationLang = this.invitationSelect.includes(value)
        ? value
        : this.invitationSelect[0]
      await this.getInvitationEmail()
      this.html = `<div>${this.invitation_email.content}</div>`
      this.show = true
    },
    async onReminderEmailChange(value) {
      if (!this.selectedHatCourse) {
        this.results = null
        this.snackbar = true
        this.snackbarText = i18n.t("view.snackbar.select_hatcourse")
        return
      }
      this.selectedReminderLang = this.reminderSelect.includes(value)
        ? value
        : this.reminderSelect[0]
      await this.getReminderEmail()
      this.html = `<div>${this.reminder_email.content}</div>`
      this.show = true
    },
    closedPreview() {
      this.html = null
      this.show = false
    },
    async getInvitationEmail() {
      this.invitation_email = await this.getEmailTypes(
        this.localCourse.invitation_email_type,
        this.selectedInvitationLang,
        this.selectedHatCourse.id
      )
    },
    async getReminderEmail() {
      this.reminder_email = await this.getEmailTypes(
        this.localCourse.reminder_email_type,
        this.selectedReminderLang,
        this.selectedHatCourse.id
      )
    },
    findInvitationEmailIndex() {
      this.invitation_index = this.availableEmailTypes.findIndex(
        (et) => et.type === this.localCourse.invitation_email_type
      )
    },
    findReminderEmailIndex() {
      this.reminder_index = this.availableEmailTypes.findIndex(
        (et) => et.type === this.localCourse.reminder_email_type
      )
    },
    initProgramAndGroups() {
      if (
        this.course &&
        this.course.employee_groups &&
        this.course.employee_groups.length > 0
      ) {
        // init selected  employee groups
        const selectEmployeeGroupsIds = this.course.employee_groups.map(
          (g) => g.id
        )
        this.selectedEmployeeGroups = this.employeeGroups.filter((group) =>
          selectEmployeeGroupsIds.includes(group.id)
        )
      }

      if (this.localCourse.hat_course) {
        this.selectedHatCourse = this.hatCourses.find(
          (p) => p.id === this.localCourse.hat_course.id
        )
      }

      if (this.localCourse.program) {
        this.selectedProgram = this.programs.find(
          (p) => p.id === this.localCourse.program.id
        )
      }

      if (this.localCourse.campaign) {
        this.selectedCampaign = this.campaigns.find(
          (p) => p.id === this.localCourse.campaign.id
        )
      }
    },

    async submit() {
      if (
        !this.selectedProgram &&
        (!this.selectedCampaign || !this.selectedCampaign.id)
      ) {
        console.error(
          "need to select a program or a campaign",
          this.selectedCampaign,
          this.selectedProgram
        )
        return
      }

      if (this.selectedHatCourse) {
        this.localCourse.hat_course_id = this.selectedHatCourse.id
      }

      if (this.selectedProgram) {
        this.localCourse.program_id = this.selectedProgram.id
      }

      if (this.selectedCampaign && this.selectedCampaign.id) {
        this.localCourse.program_id = this.selectedCampaign.program_id
        this.localCourse.campaign_id = this.selectedCampaign.id
      } else {
        this.localCourse.campaign_id = null
      }

      if (!this.localCourse.hat_course_id) {
        this.results = null
        this.snackbar = true
        this.snackbarText = i18n.t("view.snackbar.select_hatcourse")
        return
      }

      if (
        this.localCourse.has_automatic_launch &&
        this.localCourse.start_date === null
      ) {
        console.error("need to select start date")
        //return
      }

      this.localCourse.employee_groups_ids =
        this.selectedEmployeeGroups && this.selectedEmployeeGroups.length > 0
          ? this.selectedEmployeeGroups.map(($group) => $group.id)
          : []

      this.$emit("save", this.localCourse)
    },
    resetDate() {
      if (!this.localCourse.has_automatic_launch) {
        this.localCourse.start_date = null
        this.localCourse.has_automatic_stop = false
      }
    },
    updateStartDate(data) {
      this.localCourse.start_date = data
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap; /* Allows the items to wrap onto the next line */
  justify-content: space-between;
}

.flex-item {
  flex: 1 1 auto; /* Adjust this to control how the items grow and shrink */
  margin: 5px; /* Adds some space between the items */
}
.title-card {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 10px 5px;
}
.subtitle {
  font-size: 14px;
  color: #757575;
  margin: 0 0 0 5px;
}
</style>

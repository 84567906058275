<template>
  <div v-if="localData">
    <op-modale
      :display="show"
      :data="html"
      v-on:closed="closedPreview"
    ></op-modale>
    <v-card elevation="0" outlined color="#fafafa" class="ma-1">
      <v-card-text>
        <v-text-field
          :label="$t('view.learning_phishing.template_name')"
          v-model="localData.name"
          class="required"
        >
        </v-text-field>

        <v-text-field
          :label="$t('view.learning_phishing.template_subject')"
          v-model="localData.subject"
          class="required"
        >
        </v-text-field>

        <v-textarea
          :label="$t('view.learning_phishing.email_text')"
          v-model="localData.text"
        >
        </v-textarea>
        <!-- Tag syntax help section -->
        <v-card class="mt-4" outlined>
          <v-card-title>{{
            $t("view.learning_phishing.tags_help_title")
          }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="blue">mdi-format-text</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>[@text|tagName1|Default Text content]</strong>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Texte dynamique</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="
                          copyToClipboard(
                            '[@text|tagName1|Default Text content]',
                            'textCopied'
                          )
                        "
                      >
                        <v-icon v-if="!textCopied">mdi-content-copy</v-icon>
                        <v-icon v-else color="green">mdi-check</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="green">mdi-image</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong
                          >[@img|tagNumber4|https://example.com/image.jpg]</strong
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Image dynamique</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="
                          copyToClipboard(
                            '[@img|tagNumber4|https://example.com/image.jpg]',
                            'imgCopied'
                          )
                        "
                      >
                        <v-icon v-if="!imgCopied">mdi-content-copy</v-icon>
                        <v-icon v-else color="green">mdi-check</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-col cols="12" md="6">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="orange">mdi-code-tags</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>[@html|tagName2|Default HTML content]</strong>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Contenu HTML dynamique</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="
                          copyToClipboard(
                            '[@html|tagName2|Default HTML content]',
                            'htmlCopied'
                          )
                        "
                      >
                        <v-icon v-if="!htmlCopied">mdi-content-copy</v-icon>
                        <v-icon v-else color="green">mdi-check</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon color="red">mdi-palette</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>[@colorcode|EmailTextColor|#FF0000]</strong>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        >Code Couleur dynamique</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="
                          copyToClipboard(
                            '[@colorcode|EmailTextColor|#FF0000]',
                            'colorCopied'
                          )
                        "
                      >
                        <v-icon v-if="!colorCopied">mdi-content-copy</v-icon>
                        <v-icon v-else color="green">mdi-check</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-textarea
          :label="$t('view.learning_phishing.email_html')"
          v-model="localData.html"
          class="required"
        >
        </v-textarea>

        <v-select
          :label="$t('view.learning_phishing.select_attachments')"
          v-model="localData.attachments"
          :items="assetList"
          item-text="name"
          item-value="name"
          multiple
          chips
          small-chips
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" small
          >{{ $t("view.button.save") }}
        </v-btn>
        <v-btn elevation="2" @click="close()" small>{{
          $t("view.button.close")
        }}</v-btn>
        <v-btn elevation="2" @click="openPreview()" small>{{
          $t("view.button.preview")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import phishingMixin from "@/mixins/phishing.mixin.js"
import { showSnackbar } from "@/services/GlobalActions"

export default {
  mixins: [phishingMixin],
  name: "phishing-email-template-edition",
  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      localData: null,
      show: false,
      html: null,
      assetList: null,
      textCopied: false,
      imgCopied: false,
      htmlCopied: false,
      colorCopied: false,
    }
  },
  mounted() {
    this.init()
    this.localData = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    form() {
      return this.$refs.form
    },
  },
  methods: {
    async init() {
      this.assetList = await this.getPhishingResources("assets")
    },
    async submit() {
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },
    openPreview(data) {
      this.html = this.localData.html
      this.show = true
    },
    closedPreview() {
      this.html = null
      this.show = false
    },

    // Function to copy the tag syntax to the clipboard
    copyToClipboard(text, stateProp) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this[stateProp] = true

          // Show the confirmation snackbar
          showSnackbar(this.$t("view.learning_phishing.copy_success"))

          // Reset the state after 2 seconds
          setTimeout(() => {
            this[stateProp] = false
          }, 2000)
        })
        .catch((err) => {
          console.error("Erreur lors de la copie : ", err)
        })
    },
  },
}
</script>

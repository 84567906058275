<template>
  <div>
    <v-dialog v-model="dialogIsOpened" :max-width="dialogWidth">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-none rounded-lg"
          dark
          elevation="0"
          v-bind="attrs"
          v-on="on"
          >{{ $t("import") }}
        </v-btn>
      </template>
      <v-card class="pa-12 import-card" :width="dialogWidth">
        <v-card-text v-if="parsed || failure">
          <v-container>
            <!-- affichage des erreurs -->
            <v-row v-if="failure">
              <v-col
                ><div class="red--text">
                  {{ $t("employees.import.invalidFormat") }}
                </div></v-col
              ></v-row
            >
            <v-row
              v-if="errors && duplicateEmails.length > 0"
              class="employee-list"
            >
              <v-col
                ><v-subheader class="red--text"
                  >{{ $t("employees.import.duplicatedEmails") }}
                </v-subheader></v-col
              ></v-row
            >
            <v-row
              v-if="errors && duplicateEmails.length > 0"
              class="employee-list"
            >
              <OpEmployeeComponent
                v-for="employee in duplicateEmails"
                :key="employee.id"
                :employee="employee"
                class="partialStudent"
                :partial="false"
                :addButton="true"
                v-on:add="addToValidEmployeesList"
              >
              </OpEmployeeComponent>

              <v-col cols="12" class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                  v-if="errors && duplicateEmails.length > 0"
                  small
                  color="primary"
                  class="text-none rounded-lg"
                  dark
                  elevation="0"
                  @click="addAllDuplicatesToValidEmployeesList()"
                  :disabled="isLoading"
                  >{{ $t("employees.import.selectAll") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="errors && invalidEmails.length > 0">
              <v-col>
                <v-subheader class="red--text"
                  >{{ $t("employees.import.invalidEmails") }}
                </v-subheader></v-col
              ></v-row
            >
            <v-row
              v-if="errors && invalidEmails.length > 0"
              class="employee-list"
            >
              <OpEmployeeComponent
                v-for="employee in invalidEmails"
                :key="employee.id"
                :employee="employee"
                class="partialStudent"
                :partial="false"
              >
              </OpEmployeeComponent
            ></v-row>

            <!-- affichage des warnings -->
            <v-row v-if="warnings && specialStringsDetectedInEmails.length > 0">
              <v-col>
                <v-subheader class="red--text">
                  {{ $t("employees.import.specialWordsDetected") }}
                </v-subheader></v-col
              >
            </v-row>
            <v-row
              class="employee-list"
              v-if="warnings && specialStringsDetectedInEmails.length > 0"
            >
              <OpEmployeeComponent
                v-for="employee in specialStringsDetectedInEmails"
                :key="employee.id"
                :employee="employee"
                class="partialStudent"
                :partial="false"
              >
              </OpEmployeeComponent>
            </v-row>
            <v-row v-if="parsed">
              <v-col>
                <v-subheader
                  class="text-overline"
                  v-if="validNewEmployees.length > 0"
                >
                  {{
                    $tc(
                      "employees.import.validEmployeeCount",
                      validNewEmployees.length
                    )
                  }}
                </v-subheader>
                <v-subheader class="red--text" v-else>
                  {{ $t("employees.import.noValidEmployees") }}
                </v-subheader>
              </v-col>
            </v-row>
            <!-- affichage des étudiants valides uploadés -->
            <v-row v-if="parsed" class="employee-list">
              <OpEmployeeComponent
                v-for="employee in validNewEmployees"
                :key="employee.id"
                :employee="employee"
                :actions="false"
                :partial="false"
                class="partialStudent"
                :deleteButton="true"
                v-on:suppress="suppressSingleEmployeeFromValidList"
              >
              </OpEmployeeComponent>
            </v-row>
            <v-row>
              <v-col>
                <v-alert v-if="alertMessage !== ''" type="error">{{
                  alertMessage
                }}</v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- affichage des actions -->
        <v-card-actions v-if="parsed && !failure">
          <v-spacer></v-spacer>
          <v-btn
            v-if="parsed"
            @click="cancel()"
            class="mr-2 text-none rounded-lg"
            outlined
            elevation="0"
            :disabled="isLoading"
            >{{ $t("cancel") }}</v-btn
          >
          <v-dialog
            v-model="confirmationDialogIsOpened"
            :max-width="dialogWidth / 2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="validNewEmployees.length > 0"
                @click="computeDeltaEmployees()"
                color="primary"
                class="text-none rounded-lg"
                dark
                elevation="0"
                v-bind="attrs"
                v-on="on"
                :loading="isLoading"
                >Envoyer</v-btn
              >
            </template>
            <v-card class="pa-12" :width="dialogWidth / 2">
              <v-card-text
                ><div v-html="this.confirmationMessage"></div
              ></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="closeConfirmationDialog()"
                  class="mr-2 text-none rounded-lg"
                  outlined
                  elevation="0"
                  :disabled="isLoading"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  @click="saveNewEmployees()"
                  color="primary"
                  class="text-none rounded-lg"
                  dark
                  elevation="0"
                  :loading="isLoading"
                  >Confirmer</v-btn
                >
              </v-card-actions></v-card
            >
          </v-dialog>
        </v-card-actions>
        <v-file-input
          v-else
          ref="fileupload"
          accept=".csv"
          outlined
          solo
          flat
          height="195px"
          prepend-icon=""
          label="Télécharger un fichier"
          @change="parseCSVFile($event)"
          hide-details="auto"
          ><template v-slot:label
            ><div
              class="d-flex flex-column align-center justify-space-between file-input-label pt-12 pb-8"
            >
              <v-icon size="2.5rem">mdi-arrow-right</v-icon>
              <div class="">{{ $t("employees.import.uploadFile") }}</div>
              <div class="text-caption">
                {{ $t("employees.import.selectFile") }}
              </div>
            </div></template
          ></v-file-input
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="downloadDialogIsOpened" :max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon outlined small class="ml-2" v-bind="attrs" v-on="on"
          ><v-icon size="14">mdi-help</v-icon></v-btn
        ></template
      >
      <v-card class="pa-12">
        <v-card-text>{{ $t("employees.import.uploadHint") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDownloadDialog()"
            class="text-none rounded-lg"
            outlined
            light
            elevation="0"
            >{{ $t("close") }}</v-btn
          >
          <v-btn
            href="https://csg-storage-oppens.s3.eu-west-3.amazonaws.com/_store/Oppens-Load+employees-Sample-v1.csv"
            color="primary"
            class="text-none rounded-lg"
            dark
            elevation="0"
            >{{ $t("employees.import.downloadTemplate") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EmployeeRepository from "@/repositories/EmployeeRepository"
import OpEmployeeComponent from "./OpEmployeeComponent.vue"
import { isEmpty } from "lodash"

export default {
  name: "op-employee-component",
  props: {
    companyUuid: {
      type: String,
    },
    currentEmployees: {
      type: Array,
      required: true,
    },
  },
  inject: ["createEmployeeList"],
  data() {
    return {
      warningKeywords: [
        "support",
        "technique",
        "achat",
        "compta",
        "comptabilite",
        "secretariat",
        "accueil",
      ],
      isLoading: false,
      dialogIsOpened: false,
      downloadDialogIsOpened: false,
      confirmationDialogIsOpened: false,
      validNewEmployees: null,
      file: null,
      parsed: false,
      duplicateEmails: [],
      invalidEmails: [],
      specialStringsDetectedInEmails: [],
      errors: false,
      warnings: false,
      failure: false, // invalid CSV format
      alertMessage: "",
      confirmationMessage: "",
    }
  },
  methods: {
    parseCSVFile($event) {
      try {
        if (!$event) {
          return
        }
        this.alertMessage = ""
        this.file = event.target.files[0]
        this.$papa.parse(this.file, {
          delimiter: "",
          newline: "",
          quoteChar: '"',
          escapeChar: '"',
          header: false,
          skipEmptyLines: true,
          complete: function (results) {
            this.content = results
            this.parsed = true
            this.parseResults(results.data)
          }.bind(this),
        })
      } catch (e) {
        this.failure = true
      }
    },
    parseResults(data) {
      if (!data) {
        return
      }
      const parsedEmployees = []
      data.forEach((val, index) => {
        if (index === 0) {
          return
        }
        let employee = {
          informations: {
            first_name: val[0],
            last_name: val[1],
            email: val[2],
            lang: val[3],
            job: val[4],
            position: "",
            telephone: val[6],
            entity: val[7],
            department: val[8],
            city: val[9],
            country: val[10],
            manager_email: val[11],
            is_generic_email_address: val[12],
          },
        }
        parsedEmployees.push(employee)
      })
      this.checkForErrors(parsedEmployees)
    },
    /* check for duplicates, invalid emails, empty lines */
    checkForErrors(employees) {
      const countEmails = new Map()
      this.duplicateEmails = []
      this.specialStringsDetectedInEmails = []
      this.invalidEmails = []
      this.validNewEmployees = []
      this.errors = false
      employees.forEach((val) => {
        // count the number of email occurrences
        let count = countEmails.get(val.informations.email)
        if (count) {
          countEmails.set(val.informations.email, count + 1)
        } else {
          countEmails.set(val.informations.email, 1)
        }
      })
      // loop students one more time and class them in valid/invalid arrays
      employees.forEach((val) => {
        //trim the email (remove white spaces)
        val.email = val.informations.email.trim()
        if (
          isEmpty(val.informations.email) ||
          !this.validateEmail(val.informations.email)
        ) {
          this.invalidEmails.push(val)
          this.errors = true
        } else if (
          !isEmpty(val.informations.manager_email) &&
          !this.validateEmail(val.informations.manager_email)
        ) {
          this.invalidEmails.push(val)
          this.errors = true
        } else {
          // check if the encoding is correct
          // this.checkForValidEncoding(val.email); //@todo
          if (this.checkForSpecialKeywordsInEmail(val.informations.email)) {
            this.specialStringsDetectedInEmails.push(val)
            this.warnings = true
          }
          if (countEmails.get(val.email) > 1) {
            this.duplicateEmails.push(val)
            this.errors = true
          } else {
            this.validNewEmployees.push(val)
          }
        }
      })
    },
    checkForSpecialKeywordsInEmail(email) {
      const name = email.substring(0, email.lastIndexOf("@"))
      for (let keyword of this.warningKeywords) {
        if (name.includes(keyword)) {
          console.log("found substring in email", name, keyword)
          return true
        }
      }
      return false
    },
    // @todo ne fonctionne pas
    checkForValidEncoding(str) {
      try {
        let result = decodeURIComponent(str)
        console.log("good encoding ", result)
        return true
      } catch (e) {
        let result = unescape(str)
        console.log("bad encoding ", result)
        return false
      }
    },
    validateEmail(email) {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      const val = email.match(validRegex)
      if (!val) {
        console.log("email invalide : ", email)
      }
      return val
    },
    addAllDuplicatesToValidEmployeesList() {
      const validEmailsList = this.validNewEmployees.map(
        (val) => val.informations.email
      )
      this.duplicateEmails.forEach((employee) => {
        if (!validEmailsList.includes(employee.informations.email)) {
          this.validNewEmployees.push(employee)
        }
      })
    },
    addToValidEmployeesList(employee) {
      const validEmailsList = this.validNewEmployees.map(
        (val) => val.informations.email
      )
      if (!validEmailsList.includes(employee.informations.email)) {
        this.validNewEmployees.push(employee)
      }
    },
    async saveNewEmployees() {
      this.isLoading = true
      try {
        const list = JSON.parse(
          JSON.stringify(this.validNewEmployees.map((e) => e.informations))
        )
        await this.createEmployeeList(
          this.companyUuid,
          list,
          EmployeeRepository.ADD_MODE_STOCK
        )
        this.$emit("refresh-employees")
        this.reset()
        this.dialogIsOpened = false
      } catch (error) {
        this.alertMessage =
          error?.response?.data?.error?.message || this.$t("error.default")
      } finally {
        this.isLoading = false
      }
    },
    closeDownloadDialog() {
      this.downloadDialogIsOpened = false
    },
    cancel() {
      this.reset()
    },
    reset() {
      this.parsed = false
      this.validNewEmployees = null
      this.errors = false
      this.warnings = false
      this.file = null
      this.alertMessage = ""
      this.confirmationDialogIsOpened = false
    },
    suppressSingleEmployeeFromValidList(data) {
      if (!data.informations.email) {
        return
      }
      this.validNewEmployees = this.validNewEmployees.filter(
        (val) => val.informations.email !== data.informations.email
      )
    },
    closeConfirmationDialog() {
      this.confirmationDialogIsOpened = false
    },
    computeDeltaEmployees() {
      this.confirmationMessage = this.$tc(
        "employees.import.confirmationMessagePrefix",
        this.validNewEmployees.length
      )
      const missingOldEmployees = []
      const oldActiveEmployees = this.currentEmployees.filter(
        (val) => true === val.informations.active
      )
      for (let oldEmployee of oldActiveEmployees) {
        if (
          !this.validNewEmployees.find(
            (e) => e.email === oldEmployee.informations.email
          )
        ) {
          missingOldEmployees.push(oldEmployee)
        }
      }
      if (missingOldEmployees.length > 0) {
        this.confirmationMessage += this.$tc(
          "employees.import.confirmationMessageSuffix",
          missingOldEmployees.length
        )
        const maxIndex = Math.min(25, missingOldEmployees.length)
        for (let employeeIndex = 0; employeeIndex < maxIndex; employeeIndex++) {
          const element = missingOldEmployees[employeeIndex]
          this.confirmationMessage += `<li>${element.informations.email}</li>`
        }
        this.confirmationMessage += "</ul>"
        if (missingOldEmployees.length > maxIndex) {
          this.confirmationMessage += this.$tc(
            "employees.import.otherEmails",
            missingOldEmployees.length - maxIndex
          )
        }
      }
    },
  },
  computed: {
    dialogWidth() {
      if (!this.parsed) {
        return 720
      } else {
        return 1024
      }
    },
  },
  components: { OpEmployeeComponent },
}
</script>
<style scoped>
.import-card :deep(.v-input) .v-label {
  height: 100%;
}
.import-card :deep(.v-text-field.v-text-field--solo) .v-label {
  top: 0;
}
.import-card :deep(.file-input-label) {
  width: 596px;
  height: 100%;
}
.employee-list {
  max-height: 300px;
  overflow-y: auto;
}
</style>

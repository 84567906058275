<template>
  <v-container v-if="perimeter">
    <v-card>
      <v-card-title class="d-flex justify-space-between mb-6">
        <span>{{ perimeter.name }}</span>
        <v-chip small label dark color="yellow" v-if="perimeter.smart">
          <v-icon>mdi-lightbulb-on</v-icon>
        </v-chip>
      </v-card-title>

      <v-card-subtitle> {{ perimeter.slug }}</v-card-subtitle>

      <v-card-text>
        <div><i>Description: </i> {{ perimeter.description }} </div>
        <div><i>{{ $t("view.companies.number_of_companies") }}:</i>  <b>{{ perimeter.companies.length }}</b></div>
        <div v-if="perimeter.smart">
          <div class="code">
            <pre>{{ JSON.parse(perimeter.smart_rules) }}</pre>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-icon @click="suppress()" class="ma-1" elevation="2" icon
        >mdi-delete
        </v-icon
        >
        <v-btn elevation="2" small color="green" dark @click="edit()" class="ma-1">{{ $t('Edit') }}</v-btn>

      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {i18n} from "@/plugins/i18n";

export default {
  mixins: [],
  name: "oppens-perimeter",
  props: {
    perimeter: {
      default: null,
    },
  },
  data: () => ({
  }),
  methods: {
    edit() {
      this.$emit("edit", this.perimeter)
    },
    suppress() {
      this.$emit("suppress", this.perimeter)
    },
    close() {
      this.reveal = false
    },
  },
}
</script>
<style scoped>
.code {
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  padding: 10px;
  margin: 10px 0;
}
</style>

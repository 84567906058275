import AuthLogic from "@/logics/AuthLogic"
import LazyLoad from "../services/LazyLoad"
import DefaultLayout from "../views/DefaultLayout"

const routes = [
  {
    path: "/",
    name: "root",
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      if (to.name === "root") {
        next({ name: "dashboard" })
      } else {
        next()
      }
    },
    children: [
      {
        path: "logout",
        name: "logout",
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          AuthLogic.logout().then(() => {
            next({
              name: "login",
            })
          })
        },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: LazyLoad.localView("dashboard/ViewDashboard"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "system-monitoring",
        name: "system-monitoring",
        component: LazyLoad.localView("system/ViewSystemMonitoring"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "uptime-servers",
        name: "uptime-servers",
        component: LazyLoad.localView("system/ViewServersMonitoring"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "system-notifications-templates",
        name: "system-notifications-templates",
        component: LazyLoad.localView(
          "system/ViewSystemNotificationsTemplates"
        ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "system-headers-footers-templates",
        name: "system-headers-footers-templates",
        component: LazyLoad.localView(
          "system/ViewEmailHeadersFootersTemplates"
        ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "companies",
        name: "companies",
        component: LazyLoad.localView("companies/ViewCompanies"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "oppens-learning-hat-courses",
        name: "oppens-learning-hat-courses",
        component: LazyLoad.localView("learning/ViewHatCourses"),
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
      },
      {
        path: "hat-course-catalogs",
        name: "hat-course-catalogs",
        component: LazyLoad.localView("learning/ViewHatCourseCatalogs"),
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
      },
      {
        path: "oppens-learning-courses",
        name: "oppens-learning-courses",
        component: LazyLoad.localView("learning/ViewCourses"),
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
      },
      {
        path: "oppens-learning-answers",
        name: "oppens-learning-answers",
        component: LazyLoad.localView("learning/ViewAnswers"),
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
      },
      {
        path: "oppens-learning-assets",
        name: "oppens-learning-assets",
        component: LazyLoad.localView("learning/ViewAssets"),
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
      },
      {
        path: "oppens-learning-slides",
        name: "oppens-learning-slides",
        component: LazyLoad.localView("learning/ViewSlides"),
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
      },
      {
        path: "news",
        name: "news",
        component: LazyLoad.localView("news/ViewNews"),
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
      },
      {
        path: "phishing-exercise-catalogs",
        name: "phishing-exercise-catalogs",
        component: LazyLoad.localView("phishing/ViewPhishingExerciseCatalogs"),
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
      },
      {
        path: "phishing-exercises/:exerciseId",
        name: "phishing-exercises",
        component: LazyLoad.localView("phishing/ViewExercises"),
        meta: { requiresAuth: true, allowedRoles: ["admin", "manager"] },
      },
      {
        path: "phishing-servers",
        name: "phishing-servers",
        component: LazyLoad.localView("phishing/ViewServers"),
        meta: { requiresAuth: true, allowedRoles: ["admin", "manager"] },
      },
      {
        path: "phishing-landing-pages",
        name: "phishing-landing-pages",
        component: LazyLoad.localView("phishing/ViewLandingPages"),
        meta: { requiresAuth: true, allowedRoles: ["admin", "manager"] },
      },
      {
        path: "phishing-sending-profiles",
        name: "phishing-sending-profiles",
        component: LazyLoad.localView("phishing/ViewSendingProfiles"),
        meta: { requiresAuth: true, allowedRoles: ["admin", "manager"] },
      },
      {
        path: "phishing-email-templates",
        name: "phishing-email-templates",
        component: LazyLoad.localView("phishing/ViewEmailTemplates"),
        meta: { requiresAuth: true, allowedRoles: ["admin", "manager"] },
      },
      {
        path: "phishing-assets",
        name: "phishing-assets",
        component: LazyLoad.localView("phishing/ViewAssets"),
        meta: { requiresAuth: true, allowedRoles: ["admin"] },
      },
      {
        path: "users",
        name: "users",
        component: LazyLoad.localView("users/UsersList"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "access-control/",
        name: "access-control",
        component: LazyLoad.localView("access-control/ViewAccessControl"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["admin", "manager"],
        },
        children: [
          {
            path: "users",
            name: "access-control-users",
            component: LazyLoad.localView("access-control/ViewUsers"),
          },
          {
            path: "perimeters",
            name: "access-control-perimeters",
            component: LazyLoad.localView("access-control/ViewPerimeters"),
          },
          {
            path: "roles",
            name: "access-control-roles",
            component: LazyLoad.localView("access-control/ViewRoles"),
          },
        ],
      },
      {
        path: "company-management/:companyUuid",
        name: "company-management",
        component: LazyLoad.localView("company/ViewCompanyManagement"),
        meta: {
          requiresAuth: true,
          allowedRoles: ["admin", "commercial", "manager"],
        },
        children: [
          {
            path: "programs",
            name: "company-program",
            component: LazyLoad.localView("company/ViewPrograms"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "program-presets",
            name: "program-presets",
            component: LazyLoad.localView("company/ViewProgramPresets"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "campaigns",
            name: "company-campaigns",
            component: LazyLoad.localView("company/ViewCampaigns"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "simulations",
            name: "company-phishing-simulation-results",
            component: LazyLoad.localView("company/ViewSimulations"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "learning",
            name: "company-learning",
            component: LazyLoad.localView("company/ViewCompanyCourses"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "employee-groups",
            name: "company-employee-groups",
            component: LazyLoad.localView("company/ViewEmployeeGroups"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "employees",
            name: "company-employees",
            component: LazyLoad.localView("company/ViewEmployees"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "users",
            name: "company-users",
            component: LazyLoad.localView("company/ViewCompanyUsers"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "files",
            name: "company-files",
            component: LazyLoad.localView("companies/ViewCompanyFiles"),
          },
          {
            path: "logs",
            name: "company-logs",
            component: LazyLoad.localView("company/ViewCompanyLogs"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "admin",
            name: "company-admin",
            component: LazyLoad.localView("company/ViewCompanyAdmin"),
            //pathToRegexpOptions: { strict: true }
          },
          {
            path: "edit",
            name: "company-edition",
            component: LazyLoad.localView("company/ViewCompanyEdition"),
            props: { displayCompanyItem: false }
            //pathToRegexpOptions: { strict: true }
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false },
    component: LazyLoad.localView("auth/ViewLogin"),
  },
]

export default routes

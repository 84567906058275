<script>
import AuthStore from "@/stores/AuthStore"
import { i18n } from "@/plugins/i18n"
import { showSnackbar } from "@/services/GlobalActions"
import utilsMixin from "@/mixins/utils.mixin"
import usersMixin from "@/mixins/users.mixins"


export default {
  name: "company-general-edition",
  mixins: [utilsMixin, usersMixin],
  props: {
    data: {
      default: null
    },
  },
  data: function() {
    return {
      localData: null,
      companyAdminPerimeters: null,
    }
  },
  async mounted() {
    if (!this.data.uuid) {
      // the perimeters are only editable in creation mode
      let user = AuthStore.me.attributes
      this.companyAdminPerimeters = await this.getUserPerimeters(
        user,
        "company-admin",
      )
    }

    this.localData = JSON.parse(JSON.stringify(this.data))
  },

  watch: {
    data(newValue, oldValue) {
      this.localData = JSON.parse(JSON.stringify(newValue))
    },
  },
  computed: {
    isMagicLink: {
      get() {
        return this.localData.employees_auth_method === "magic_link";
      },
      set(value) {
        if (value) this.localData.employees_auth_method = "magic_link";
      }
    },
    isEmailPwd: {
      get() {
        return this.localData.employees_auth_method === "email_pwd";
      },
      set(value) {
        if (value) this.localData.employees_auth_method = "email_pwd";
      }
    },
    isSSOAzure: {
      get() {
        return this.localData.employees_auth_method === "sso";
      },
      set(value) {
        if (value) this.localData.employees_auth_method = "sso";
      }
    }
  },
  methods: {
    async validate() {
      // Perform validation logic - Return true if validation passes, false otherwise
      if (this.localData.perimeter_id === null) {
        showSnackbar(i18n.t("view.companies.please_select_perimeters"))
        return false
      }

      return true
    },
    getCompanyData() {
      // Return the company data specific to this component
      return {
        business_name: this.localData.business_name,
        trade_name: this.localData.trade_name,
        siren_number: this.localData.siren_number,
        tva_number: this.localData.tva_number,
        phone_number: this.localData.phone_number,
        email_address: this.localData.email_address,
        website: this.localData.website,
        perimeter_id: this.localData.perimeter_id,
        employees_auth_method: this.localData.employees_auth_method,
      }
    },
  },
}
</script>

<template>
  <div class="flex-container" v-if="localData">
    <!-- Identity -->
    <div class="bloc">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("profile.identity") | titlecase }}
          </div>
        </v-col>

        <v-col cols="12">
          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="business_name"
              type="text"
              rules=""
              color="green"
              v-model="localData.business_name"
              :label="$t('profile.business_name')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="trade_name"
              type="text"
              rules=""
              color="green"
              v-model="localData.trade_name"
              :label="$t('profile.trade_name')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="siren_number"
              type="text"
              :rules="'min:9|max:9'"
              color="green"
              v-model="localData.siren_number"
              :label="$t('profile.siren_number')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="tva_number"
              type="text"
              rules=""
              color="green"
              v-model="localData.tva_number"
              :label="$t('profile.tva_number')"
            >
            </op-text-field>
          </v-card-text>
        </v-col>
      </v-row>
    </div>

    <!-- Address -->
    <div class="bloc">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("profile.contact") | titlecase }}
          </div>
        </v-col>

        <v-col cols="12">
          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="phone_number"
              color="green"
              v-model="localData.phone_number"
              :label="$t('profile.phone_number')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="email_address"
              rules="email"
              color="green"
              v-model="localData.email_address"
              :label="$t('profile.company_email_address')"
            >
            </op-text-field>
          </v-card-text>

          <v-card-text class="px-3 pa-0">
            <op-text-field
              name="website"
              type="text"
              color="green"
              v-model="localData.website"
              :label="$t('profile.website')"
            >
            </op-text-field>
          </v-card-text>
        </v-col>
      </v-row>
    </div>

    <!-- Choix du type -->
    <div class="bloc">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{
              $t("profile.oppens_learning_training_settings") | titlecase
            }}
          </div>
        </v-col>
        <v-col cols="12">
          {{ $t("profile.authentication_method") }}

          <v-switch
            v-model="isMagicLink"
            flat
            :label="$t('profile.magic_link')"
          ></v-switch>

          <v-switch
            v-model="isEmailPwd"
            flat
            :label="$t('profile.email_pwd')"
          ></v-switch>

          <v-switch
            v-model="isSSOAzure"
            flat
            :label="$t('Authentication SSO')"
          ></v-switch>
        </v-col>

      </v-row>
    </div>

    <!--Choix du périmètre (uniquement en mode création)-->
    <div class="bloc" v-if="!localData.uuid">
      <v-row>
        <v-col cols="12">
          <div class="bloc-title">
            {{ $t("profile.choice_of_perimeters") | titlecase }}
          </div>
          <div class="mt-5">
            <v-select
              v-model="localData.perimeter_id"
              :items="companyAdminPerimeters"
              item-text="name"
              item-value="id"
              :label="$t('profile.perimeter')"
              class="required"
              outlined
              dense
              required
              clearable
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.bloc {
  border: 1px solid #efefef;
  border-radius: 5px;
  flex: 1 1 auto;
  width: 250px;
  padding: 20px;
  margin: 10px;

  .bloc-title {
    font-weight: bold;
    text-align: center;
    font-size: 1.5em;
    color: #2a2929;
  }
}
</style>

<template>
  <div v-if="data">
    <v-card elevation="0" outlined class="ma-1">
      <v-card-title>{{ data.name }}</v-card-title>
      <v-card-subtitle>
        <div>{{ $t("view.learning_phishing.host") }} : {{ data.host }}</div>
        <div>{{ $t("view.learning_phishing.username") }} : {{ data.username }}</div>
        <div>{{ $t("view.learning_phishing.from_address") }} : {{ data.from_address }}</div>
        <div>{{ $t("view.learning_phishing.interface_type") }} : {{ data.interface_type }}</div>
      </v-card-subtitle>

      <v-card-text>
        <h3 class="mt-2">{{ $t("view.learning_phishing.testing_the_sending_profile") }}</h3>
        <v-row>
          <v-col cols="5">
            <v-text-field
              :label="$t('view.learning_phishing.destination_email')"
              v-model="destination_email"
              class="required"
            >
            </v-text-field>
          </v-col>

          <v-col cols="5">
            <v-text-field
              :label="$t('view.learning_phishing.recipient_name')"
              v-model="destination_name"
              class="required"
            >
            </v-text-field>
          </v-col>

          <v-col cols="2">
            <v-btn elevation="2" small @click="test()" dark>{{ $t("view.button.send") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" small @click="duplicate()">{{ $t("view.button.duplicate") }}</v-btn>
        <v-btn elevation="2" small @click="edit()" color="green" dark
        >{{ $t("view.button.edit") }}
        </v-btn
        >
        <v-icon @click="suppress()" class="ml-2" elevation="2" icon
        >mdi-delete
        </v-icon
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import {i18n} from "@/plugins/i18n";

export default {
  name: "phishing-sending-profile",
  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      destination_email: null,
      destination_name: null,
      host: null,
      port: null,
    }
  },

  methods: {
    mounted() {
    },
    async suppress() {
      this.$emit("suppress", this.data)
    },
    edit() {
      this.$emit("edit", this.data)
    },
    duplicate() {
      this.$emit("duplicate", this.data)
    },
    async test() {
      if (this.data && this.data.host) {
        // check if host string includes the port with format address:port
        if (this.data.host.includes(":")) {
          const exploded = this.data.host.split(":")
          if (exploded.length > 1) {
            this.host = exploded[0]
            this.port = exploded[1]
          } else {
            console.error("no explositon", exploded)
          }
        } else {
          this.host = this.data.host
          this.port = null
        }

        // explode host into host url & port
      } else {
        console.error("no data host", this.data)
      }

      const obj = {
        destination_email: this.destination_email,
        destination_name: this.destination_name,
        subject: i18n.t('Test email'),
        from_address: this.data.from_address,
        name: this.data.name,
        host: this.host,
        port: this.port,
        password: this.data.password,
        username: this.data.username,
      }

      this.$emit("test", obj)
    },
  },
}
</script>

<template>
  <div v-if="localData">
    <v-card elevation="0" outlined color="#fafafa" class="ma-1">
      <v-card-text>
        <v-textarea
          :label="$t('Content')"
          v-model="localData.content"
          class="required"
        ></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" small
        >{{ $t('Save') }}
        </v-btn
        >
        <v-btn elevation="2" @click="close()" small>{{ $t('Close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "system-header-footer-template-edition",
  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      localData: null,
    }
  },
  mounted() {
    this.localData = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    form() {
      return this.$refs.form
    },
  },
  methods: {
    async submit() {
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },

  },
}
</script>

import AdminRepository from "../repositories/AdminRepository"
import UserRepository from "../repositories/UserRepository"
import DashboardStore from "../stores/DashboardStore"
import ModelList from "../models/ModelList"
import User from "../models/User"
import CompanyRepository from "@/repositories/CompanyRepository"

export default class AdminLogic {
  static async refreshDashboard() {
    const response = await AdminRepository.getDashboard()
    let obj = response.responseObject()
    DashboardStore.setObj(obj.data)
    return DashboardStore.state.obj
  }

  static async getDashboard() {
    let obj = DashboardStore.state.obj
    if (!obj) {
      const response = await AdminRepository.getDashboard()
      let obj = response.responseObject()
      DashboardStore.setObj(obj.data)
    }

    return DashboardStore.state.obj
  }

  static async getBatchCommandsLogs() {
    const response = await AdminRepository.getBatchCommandsLogs()
    let obj = response.responseObject()
    return obj.data
  }

  static async importCoursesAndHatCourses() {
    const response = await AdminRepository.importCoursesAndHatCourses()
    return response.data
  }

  static async getAllUsers() {
    const response = await UserRepository.getAllNonAnonymousUsers()
    return response.responseObject().data
  }

  static async refreshDashboardData() {
    await AdminRepository.refreshDashboardData()
  }

  static async getCompanyLogs(companyUuid, includeRawJson = false) {
    const body = {
      company_uuid: companyUuid,
      include_raw_json_content: includeRawJson,
    }

    const response = await CompanyRepository.getCompanyLogs(companyUuid, body)
    return response.responseObject().data
  }

  //------------------------ Notifications Templates ------------------------//
  static async getAllNotificationsTemplates() {
    const response = await AdminRepository.getAllNotificationsTemplates()
    return response.responseObject().data
  }

  static async getNotificationTemplate(templateUuid) {
    const response = await AdminRepository.getNotificationTemplate(templateUuid)
    return response.responseObject().data
  }

  static async saveNotificationsTemplate(id, body) {
    if (id) {
      const response = await AdminRepository.updateNotificationsTemplate(
        id,
        body
      )
      return response.responseObject().data
    } else {
      const response = await AdminRepository.createNotificationsTemplate(body)
      return response.responseObject().data
    }
  }

  static async createNotificationsTemplate(body) {
    const response = await AdminRepository.createNotificationsTemplate(body)
    return response.responseObject().data
  }

  static async updateNotificationsTemplate(templateUuid, body) {
    const response = await AdminRepository.updateNotificationsTemplate(
      templateUuid,
      body
    )
    return response.responseObject().data
  }

  static async deleteNotificationsTemplate(templateUuid) {
    const response = await AdminRepository.deleteNotificationsTemplate(
      templateUuid
    )
    return response.responseObject().data
  }

  static async getAllEmailFooters() {
    const response = await AdminRepository.getAllEmailFooters()
    return response.responseObject().data
  }

  static async getAllEmailHeaders() {
    const response = await AdminRepository.getAllEmailHeaders()
    return response.responseObject().data
  }

  //------------------------ Stimuli & Thematics ------------------------//
  static async getAllStimuli() {
    const response = await AdminRepository.getAllStimuli()
    let list = response.responseObject()
    return list.data
  }

  static async getAllThematics() {
    const response = await AdminRepository.getAllThematics()
    let list = response.responseObject()
    return list.data
  }

  //------------------------ Users ------------------------//
  static async getUserPerimeters(userUuid, roleSlug = null) {
    let params = roleSlug
      ? {
          role_slug: roleSlug,
        }
      : {}

    const response = await AdminRepository.getUserPerimeters(userUuid, params)
    let list = response.responseObject()
    return list.data
  }
  //------------------------ Headers & Footers Templates ------------------------//
  static async getEmailHeaderTemplate() {
    const response = await AdminRepository.getEmailHeaderTemplate()
    return response.responseObject().data
  }

  static async saveEmailHeaderTemplate(id, body) {
    if (id) {
      const response = await AdminRepository.updateEmailHeaderTemplate(
        id,
        body
      )
      return response.responseObject().data
    } else {
      const response = await AdminRepository.createEmailHeaderTemplate(body)
      return response.responseObject().data
    }
  }

  static async createEmailHeaderTemplate(body) {
    const response = await AdminRepository.createEmailHeaderTemplate(body)
    return response.responseObject().data
  } 
  
  static async updateEmailHeaderTemplate(templateUuid, body) {
    const response = await AdminRepository.updateEmailHeaderTemplate(
      templateUuid,
      body
    )
    return response.responseObject().data
  }

  static async deleteEmailHeaderTemplate(templateUuid) {
    const response = await AdminRepository.deleteEmailHeaderTemplate(
      templateUuid
    )
    return response.responseObject().data
  }  

  static async duplicateEmailHeaderTemplate(templateUuid) {
  const response = await AdminRepository.duplicateEmailHeaderTemplate(
    templateUuid
  )
  return response.responseObject().data
  }

  static async getEmailFooterTemplate() {
  const response = await AdminRepository.getEmailFooterTemplate()
  return response.responseObject().data
  }

    static async saveEmailFooterTemplate(id, body) {
    if (id) {
      const response = await AdminRepository.updateEmailFooterTemplate(
        id,
        body
      )
      return response.responseObject().data
    } else {
      const response = await AdminRepository.createEmailFooterTemplate(body)
      return response.responseObject().data
    }
  }

  static async createEmailFooterTemplate(body) {
  const response = await AdminRepository.createEmailFooterTemplate(body)
  return response.responseObject().data
  } 

  static async updateEmailFooterTemplate(templateUuid, body) {
  const response = await AdminRepository.updateEmailFooterTemplate(
    templateUuid,
    body
  )
  return response.responseObject().data
  }

  static async deleteEmailFooterTemplate(templateUuid) {
  const response = await AdminRepository.deleteEmailFooterTemplate(
    templateUuid
  )
  return response.responseObject().data
  }  

  static async duplicateEmailFooterTemplate(templateUuid) {
  const response = await AdminRepository.duplicateEmailFooterTemplate(
    templateUuid
  )
  return response.responseObject().data
  }  
}

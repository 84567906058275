var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.localData)?_c('div',[_c('op-modale',{attrs:{"display":_vm.show,"data":_vm.html},on:{"closed":_vm.closedPreview}}),_c('v-card',{staticClass:"ma-1",attrs:{"elevation":"0","outlined":"","color":"#fafafa"}},[_c('v-card-text',[_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('view.learning_phishing.page_name')},model:{value:(_vm.localData.name),callback:function ($$v) {_vm.$set(_vm.localData, "name", $$v)},expression:"localData.name"}}),_c('v-text-field',{staticClass:"required",attrs:{"label":_vm.$t('view.learning_phishing.url_to_server')},model:{value:(_vm.localData.url),callback:function ($$v) {_vm.$set(_vm.localData, "url", $$v)},expression:"localData.url"}}),_c('v-card',{staticClass:"mt-4",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("view.learning_phishing.tags_help_title")))]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-format-text")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v("[@text|tagName1|Default Text content]")])]),_c('v-list-item-subtitle',[_vm._v("Texte dynamique")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(
                          '[@text|tagName1|Default Text content]',
                          'textCopied'
                        )}}},[(!_vm.textCopied)?_c('v-icon',[_vm._v("mdi-content-copy")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1)],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-image")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v("[@img|tagNumber4|https://example.com/image.jpg]")])]),_c('v-list-item-subtitle',[_vm._v("Image dynamique")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(
                          '[@img|tagNumber4|https://example.com/image.jpg]',
                          'imgCopied'
                        )}}},[(!_vm.imgCopied)?_c('v-icon',[_vm._v("mdi-content-copy")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-code-tags")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v("[@html|tagName2|Default HTML content]")])]),_c('v-list-item-subtitle',[_vm._v("Contenu HTML dynamique")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(
                          '[@html|tagName2|Default HTML content]',
                          'htmlCopied'
                        )}}},[(!_vm.htmlCopied)?_c('v-icon',[_vm._v("mdi-content-copy")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1)],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-palette")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',[_vm._v("[@colorcode|EmailTextColor|#FF0000]")])]),_c('v-list-item-subtitle',[_vm._v("Code Couleur dynamique")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(
                          '[@colorcode|EmailTextColor|#FF0000]',
                          'colorCopied'
                        )}}},[(!_vm.colorCopied)?_c('v-icon',[_vm._v("mdi-content-copy")]):_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-textarea',{staticClass:"required",attrs:{"label":_vm.$t('view.learning_phishing.page_html')},model:{value:(_vm.localData.html),callback:function ($$v) {_vm.$set(_vm.localData, "html", $$v)},expression:"localData.html"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('view.learning_phishing.capture_credentials')},model:{value:(_vm.localData.capture_credentials),callback:function ($$v) {_vm.$set(_vm.localData, "capture_credentials", $$v)},expression:"localData.capture_credentials"}}),(_vm.localData.capture_credentials)?_c('div',[_c('v-checkbox',{attrs:{"label":_vm.$t('view.learning_phishing.capture_password')},model:{value:(_vm.localData.capture_password),callback:function ($$v) {_vm.$set(_vm.localData, "capture_password", $$v)},expression:"localData.capture_password"}}),_c('v-text-field',{attrs:{"label":_vm.$t('view.learning_phishing.redirect_url_of_the_page')},model:{value:(_vm.localData.redirect_url),callback:function ($$v) {_vm.$set(_vm.localData, "redirect_url", $$v)},expression:"localData.redirect_url"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{attrs:{"elevation":"2","dark":"","color":"green","small":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.$t("view.button.save"))+" ")]),_c('v-btn',{attrs:{"elevation":"2","small":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t("view.button.close")))]),_c('v-btn',{attrs:{"elevation":"2","small":""},on:{"click":function($event){return _vm.openPreview()}}},[_vm._v(_vm._s(_vm.$t("view.button.preview")))])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
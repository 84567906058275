<template>
  <div v-if="data">
    <v-card elevation="0" outlined class="ma-1">
      <v-card-title class="ma-2 pb-2 d-flex justify-space-between">
        <div>{{ data.title }}</div>
        <div>
          <v-chip small label dark color="green" v-if="data.theme" class="mr-2">
            {{ data.theme }}
          </v-chip>

          <v-chip small label dark color="blue" class="mr-2">
            {{ data.lang }}
          </v-chip>

          <v-chip
            small
            label
            dark
            color="purple"
            v-if="data.archived"
            class="mr-2"
          >
            {{ $t("view.learning_phishing.archived") }}
          </v-chip>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="infos">
          <div v-if="data.description">
            {{ $t("view.learning_phishing.description") }} :
            {{ data.description }}
          </div>

          <div>
            {{ $t("view.learning_phishing.server") }} :
            <a :href="data.phishing_server.url" target="_blank">{{
              data.phishing_server.url
            }}</a>
            <!--v-tooltip slot="append" top>
              <template v-slot:activator="{ on }">
                <v-icon :color="getStatus(status).color" v-on="on" class="ms-6">
                  {{ getStatus(status).icon }}
                </v-icon>
              </template>
              <span>Status: {{ status }}</span>
            </v-tooltip-->
          </div>

          <div>
            {{ $t("view.learning_phishing.email_template") }} :
            {{ data.phishing_email_template.name }}
          </div>

          <div>
            {{ $t("view.learning_phishing.landing_page") }} :
            {{ data.phishing_landing_page.name }}
          </div>

          <div v-if="data.phishing_sending_profile">
            {{ $t("view.learning_phishing.sending_profile") }} :
            {{ data.phishing_sending_profile.host }}
          </div>

          <div>
            Stimuli :
            <v-chip
              small
              label
              dark
              color="light-blue"
              v-for="stimulus in data.stimuli"
              v-bind:key="stimulus.id"
              class="mr-2"
            >
              {{ stimulus.slug }}
            </v-chip>
          </div>

          <div>
            {{ $t("view.learning_phishing.themes") }} :
            <v-chip
              small
              label
              dark
              color="orange"
              v-for="thematic in data.thematics"
              v-bind:key="thematic.id"
              class="mr-2"
            >
              {{ thematic.slug }}
            </v-chip>
          </div>

          <div v-if="data.documentation_url">
            {{ $t("view.learning_phishing.documentation_url") }} :
            <a :href="data.documentation_url" target="_blank">{{
              data.documentation_url
            }}</a>
          </div>
        </div>

        <v-row v-if="data.illustration_url" class="mb-5 d-flex justify-end">
          <v-img
            max-height="150"
            max-width="250"
            :src="data.illustration_url"
            class="float-right"
          ></v-img>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" small @click="displayTemplates()"
          >{{ $t("view.learning_phishing.display_templates") }}
        </v-btn>
        <v-btn elevation="2" small @click="duplicate()">{{
          $t("view.button.duplicate")
        }}</v-btn>
        <v-btn elevation="2" small @click="edit()">{{
          $t("view.button.edit")
        }}</v-btn>

        <v-icon
          @click="archive()"
          class="ml-2"
          elevation="2"
          icon
          v-if="!data.archived"
        >
          mdi-archive
        </v-icon>

        <v-icon @click="suppress()" class="ml-2" elevation="2" icon
          >mdi-delete
        </v-icon>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import phishingMixin from "@/mixins/phishing.mixin"
import monitoringMixins from "@/mixins/monitoring.mixins.js"

export default {
  name: "phishing-exercise",
  mixins: [phishingMixin, monitoringMixins],
  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      monitoringRecord: null,
      status: null,
    }
  },

  async mounted() {
    if (this.data && this.data.id) {
      /*this.monitoringRecord = await this.getServerStatus(
        this.data.phishing_server.id
      )
      if (this.monitoringRecord) {
        this.status = this.monitoringRecord.status
      }
      console.log("monitoring", this.monitoringRecord)
      */

    }
  },
  methods: {
    getStatus(status) {
      const statusMap = {
        up: { icon: "mdi-check-circle", color: "green" },
        down: {
          icon: "mdi-close-circle",
          color: "red",
        },
        unknown: { icon: "mdi-alert-circle-outline", color: "grey darken-3" },
      }

      return statusMap[status] || statusMap.unknown
    },
    async suppress() {
      this.$emit("suppress", this.data)
    },
    edit() {
      this.$emit("edit", this.data)
    },
    archive() {
      this.$emit("archive", this.data)
    },
    duplicate() {
      this.$emit("duplicate", this.data)
    },
    async displayTemplates() {
      const templates = await this.getExerciseCompiledTemplates(this.data.id)
      this.$emit("view", templates)
    },
  },
}
</script>
<style scoped lang="scss">
.infos {
  margin-left: 20px;

  > div {
    margin-bottom: 5px;
  }
}
</style>

<template>
  <div v-if="data">
    <v-card elevation="0" outlined class="ma-1">
      <span>
        <v-card-title>
          <v-tooltip slot="append" top>
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                class="id-icon mx-2"
                small
                label
                dark
                color="#4285F4"
              >{{ data.id }}</v-chip
              >
            </template>
            <span>{{ $t('ID') }}</span>
          </v-tooltip>

          {{ data.attributes.title }} {{ getLangInUpperCase(data.attributes.lang)}}
        </v-card-title>
      </span>
      <v-card-subtitle>
        <div>{{ $t('Subject') }} : {{ data.attributes.subject }}</div>
        <div>{{ $t('Type') }} : {{ data.attributes.type }}</div>
        <!-- <div>{{ $t('Language') }} : {{ data.attributes.lang }}</div> -->
        <div>{{ $t('Company ID') }} : {{ data.attributes.company_id }}</div>
        <div>
          {{ $t('Creation date') }} : {{ formatDate(data.attributes.created_at) }}
        </div>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" small @click="duplicate()">{{ $t('Duplicate') }}</v-btn>
        <v-btn elevation="2" small @click="edit()">{{ $t('Edit') }}</v-btn>
        <v-btn elevation="2" small dark color="green" @click="viewCode()"
        >Voir
        </v-btn
        >
        <v-icon @click="suppress()" class="ml-2" elevation="2" icon
        >mdi-delete
        </v-icon
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "system-notification-template",
  props: {
    data: {
      attributes: null,
      email_footer: null,
      email_header: null,
    },
  },
  methods: {
    getLangInUpperCase(lang) {
      return lang.toUpperCase();
    },
    viewCode() {
      this.$emit(
        "view",
        this.data.email_header.content +
        this.data.attributes.content +
        this.data.email_footer.content
      )
    },
    async suppress() {
      this.$emit("suppress", this.data)
    },
    edit() {
      this.$emit("edit", this.data)
    },
    duplicate() {
      this.$emit("duplicate", this.data)
    },
    formatDate(date) {
      if (!date) return
      return moment(date).format("DD/MM/YYYY à HH:mm")
    },
  },
}
</script>

<template>
  <div>
    <op-confirmation-dialog ref="confirm" />

    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>

    <op-exercise-preview-modale
      :display="showCompiledTemplates"
      :data="compiledTemplates"
      v-on:closed="closedPreview"
    ></op-exercise-preview-modale>

    <!-- Course Edition Dialog -->
    <v-dialog
      eager
      v-if="selectedCourse"
      v-model="dialogUpdateCourse"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
    >
      <v-card outlined color="#fafafa">
        <v-card-title class="d-flex">
          {{ title }}
        </v-card-title>

        <v-progress-linear
          v-if="loading"
          indeterminate
          color="yellow darken-2"
        ></v-progress-linear>

        <learning-company-course-edition
          :course="selectedCourse"
          :campaignId="campaign.id"
          v-on:close="closeEdition"
          v-on:save="updateCompanyCourse"
          class="container"
        >
        </learning-company-course-edition>
      </v-card>
    </v-dialog>

    <!-- Course Creation Dialog -->
    <v-dialog
      v-if="newCourse"
      eager
      v-model="dialogNewCourse"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
    >
      <v-card outlined color="#fafafa">
        <v-card-title class="d-flex">
          {{ title }}
        </v-card-title>

        <learning-company-course-edition
          :course="newCourse"
          :campaignId="campaign.id"
          v-on:save="updateCompanyCourse"
          v-on:close="closeEdition"
          class="container"
        >
        </learning-company-course-edition>
      </v-card>
    </v-dialog>

    <!-- Simulation Edition Dialog -->
    <v-dialog
      eager
      v-if="simulationToEdit"
      v-model="dialogUpdateSimulation"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
    >
      <v-card outlined color="#fafafa">
        <v-card-title class="d-flex">
          {{ title }}
        </v-card-title>

        <phishing-simulation-edition
          :simulation="simulationToEdit"
          :campaignId="campaign.id"
          v-on:save="saveSimulationEdited"
          v-on:close="closeEdition"
          v-on:view="openPreview"
          class="container"
        ></phishing-simulation-edition>
      </v-card>
    </v-dialog>

    <!-- New Simulation Dialog -->
    <v-dialog
      v-if="newSimulation"
      eager
      v-model="dialogNewSimulation"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
    >
      <v-card outlined color="#fafafa">
        <v-card-title class="d-flex">
          {{ title }}
        </v-card-title>

        <phishing-simulation-edition
          :simulation="newSimulation"
          :campaignId="campaign.id"
          v-on:save="saveNewSimulation"
          v-on:close="closeNewSimulation"
          v-on:view="openPreview"
          class="container"
        >
        </phishing-simulation-edition>
      </v-card>
    </v-dialog>

    <!-- Pop-up confirmation of report generation -->
    <v-dialog
      eager
      v-model="dialog"
      :max-width="400"
      :style="{ zIndex: options.zIndex }"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold black--text">
            {{ $t("Confirmation of report generation") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text">
          <span>{{ $t("Are you sure you want to generate a report?") }}</span>
        </v-card-text>

        <v-select
          class="ma-4"
          v-model="language"
          :label="$t('Language')"
          rounded-0
          :items="['fr', 'en', 'ro', 'de']"
          @change="languageSelected"
        ></v-select>

        <v-select
          class="ma-4"
          v-model="typeOfReport"
          :label="$t('Type of report')"
          rounded-0
          :items="typeOfReportItems"
          item-text="value"
          item-value="slug"
          @change="typeOfReportSelected"
        ></v-select>

        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            small
            text
            @click.native="cancel"
            class="body-2 font-weight-bold"
            >{{ i18n().t("view.companies.give_up") }}
          </v-btn>
          <v-btn
            color="green"
            small
            @click.native="confirm"
            class="body-2 font-weight-bold"
            outlined
            >{{ $t("Confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Main campaign card -->
    <v-card elevation="0" v-if="localData">
      <v-card-title class="d-flex justify-space-between">
        <v-row>
          <v-col cols="9">
            <v-chip small label dark color="#cfcfcf" class="mr-2">
              {{ localData.id }}
            </v-chip>

            <span @click="editName = true" v-if="!editName"
              >{{ localData.name }}
            </span>

            <input
              v-if="editName"
              v-model="localData.name"
              @blur="editName = false"
              @keyup.enter="editName = false"
            />
          </v-col>
          <v-col cols="3" class="d-flex justify-lg-end space-between">
            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <div
                  class="icon-container ma-2"
                  v-on="on"
                  @click="startNewCourse()"
                >
                  <v-icon> mdi-school</v-icon>
                  <span class="formation-plus">+</span>
                </div>
              </template>
              <span>{{ $t("view.companies.new_learning") }}</span>
            </v-tooltip>

            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <div
                  class="icon-container ma-1"
                  v-on="on"
                  @click="startNewSimulation()"
                >
                  <v-icon> mdi-hook</v-icon>
                  <span class="simulation-plus">+</span>
                </div>
              </template>
              <span>{{ $t("view.companies.new_simulation") }}</span>
            </v-tooltip>

            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="generateReport()" class="ma-2"
                  >mdi-chart-bar
                </v-icon>
              </template>
              <span>{{ $t("view.button.generate_a_report") }}</span>
            </v-tooltip>

            <v-tooltip slot="append" bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="suppress()" class="ma-2"
                  >mdi-delete
                </v-icon>
              </template>
              <span>{{ $t("view.button.delete_a_campaign") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-subtitle>
        <span @click="editDescription = true" v-if="!editDescription">{{
          localData.description || "-----"
        }}</span>

        <v-text-field
          v-if="editDescription"
          :label="$t('view.companies.campaign_description')"
          v-model="localData.description"
          @blur="editDescription = false"
          @keyup.enter="editDescription = false"
        >
        </v-text-field>
      </v-card-subtitle>

      <learning-company-course
        v-for="course in campaign.company_courses"
        v-bind:key="course.id"
        class="mt-2 box-1"
        v-bind:data="course"
        v-on:edit="edit"
        v-on:suppress="confirmDeleteCourse"
        v-on:start="startThisCompanyCourse"
        v-on:stop="stopThisCompanyCourse"
        v-on:invite="inviteUserToCourse"
        v-on:remind="remindUsersOfCourse"
        :modeLine="true"
      >
      </learning-company-course>

      <phishing-simulation
        v-for="simulation in filteredTestSimulations"
        :key="simulation.id"
        class="mt-2 box-1"
        v-bind:data="simulation"
        v-bind:companyUuid="companyUuid"
        v-on:edit="editSimulation"
        v-on:suppress="confirmDeleteSimulation"
        v-on:launch="confirmLaunch"
        v-on:duplicate="duplicate"
        v-on:stop="confirmStop"
        v-on:getResults="getSimulationResults"
        v-on:view="openPreview"
        v-on:createTest="confirmCreateTest"
        :modeLine="true"
      ></phishing-simulation>
    </v-card>
  </div>
</template>

<script>
import { showSnackbar } from "@/services/GlobalActions"
import phishingMixin from "@/mixins/phishing.mixin"
import learningMixin from "@/mixins/learning.mixin"
import campaignsMixin from "@/mixins/campaigns.mixin"
import programsMixin from "@/mixins/programs.mixin"
import { i18n } from "@/plugins/i18n"

export default {
  mixins: [phishingMixin, learningMixin, campaignsMixin, programsMixin],
  name: "oppens-campaign",
  props: {
    campaign: {
      default: null,
    },
    simulations: {
      default: null,
    },
    selectedSimulation: {
      default: null,
    },
  },

  data: () => ({
    title: "",
    dialogDuplicateSimulation: false,
    dialogNewSimulation: false,
    dialogUpdateSimulation: false,
    dialogNewCourse: false,
    dialogUpdateCourse: false,
    newCourse: null,
    loading: false,
    selectedCourse: null,
    dialog: false,
    language: "fr",
    editName: false,
    editDescription: false,
    localData: null,
    simulationsItems: [],
    coursesItems: [],
    options: {
      color: "grey lighten-3",
      width: 1000,
      zIndex: 200,
    },
    simulationToEdit: null,
    showCompiledTemplates: false,
    compiledTemplates: null,
    newSimulation: null,
    typeOfReport: "campaign-records",
    typeOfReportItems: [
      { slug: "campaign-records", value: i18n.t("Historical report") },
      { slug: "single-campaign", value: i18n.t("Simple report") },
      { slug: "stress-test", value: i18n.t("Stress Test Report") },
    ],
  }),

  async mounted() {
    this.localData = JSON.parse(JSON.stringify(this.campaign))
  },

  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },

    filteredTestSimulations() {
      const activeSimulations = this.campaign.simulations.filter(
        (simulation) => simulation.is_active && !simulation.is_test
      )
      const stoppedSimulations = this.campaign.simulations.filter(
        (simulation) => simulation.is_stopped && !simulation.is_test
      )
      const activeSimulationsAndTest = this.campaign.simulations.filter(
        (simulation) => simulation.is_active
      )
      const stoppedSimulationsAndTest = this.campaign.simulations.filter(
        (simulation) => simulation.is_stopped
      )
      if (!this.campaign || !this.campaign.simulations) return []
      if (
        this.selectedSimulation.includes("Test") &&
        this.selectedSimulation.includes("Arrêtée") &&
        this.selectedSimulation.includes("Active")
      ) {
        return [...activeSimulationsAndTest, ...stoppedSimulationsAndTest]
      } else if (
        this.selectedSimulation.includes("Active") &&
        this.selectedSimulation.includes("Arrêtée")
      ) {
        return [...activeSimulations, ...stoppedSimulations]
      }
      if (
        this.selectedSimulation.includes("Active") &&
        this.selectedSimulation.includes("Test")
      ) {
        return activeSimulationsAndTest
      }
      if (
        this.selectedSimulation.includes("Test") &&
        this.selectedSimulation.includes("Arrêtée")
      ) {
        return stoppedSimulationsAndTest
      } else if (this.selectedSimulation.includes("Active")) {
        return activeSimulations
      } else if (this.selectedSimulation.includes("Arrêtée")) {
        return stoppedSimulations
      } else if (this.selectedSimulation.includes("Test")) {
        return this.campaign.simulations.filter(
          (simulation) => simulation.is_test
        )
      } else {
        return this.campaign.simulations
      }
    },
  },

  methods: {
    i18n() {
      return i18n
    },

    async inviteUserToCourse(course) {
      const response = await this.inviteUsersToCompanyCourse(course)
      showSnackbar(response)
      this.$emit("refresh")
    },

    async remindUsersOfCourse(course) {
      const response = await this.remindUsersOfCompanyCourse(course)
      showSnackbar(response)
      this.$emit("refresh")
    },

    async edit(data) {
      this.title = i18n.t("view.companies.edit_a_learning")
      this.dialogUpdateCourse = true
      this.selectedCourse = await this.getOneCourse(this.companyUuid, data.id)
      this.selectedCourse.employee_groups = data.employee_groups
    },

    closeEdition() {
      this.title = ""
      this.selectedCourse = null
      this.newCourse = null
      this.simulationToEdit = null
      this.dialogUpdateCourse = false
      this.dialogNewCourse = false
      this.dialogUpdateSimulation = false
      this.dialogNewSimulation = false
      this.dialogDuplicateSimulation = false
    },

    async confirmDeleteCourse(data) {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_delete"),
          i18n.t("view.companies.confirm_course_delete"),
          i18n.t("Cancel")
        )
      ) {
        await this.suppressCompanyCourse(data)
        this.$emit("refresh")
      }
    },

    async updateCompanyCourse(course) {
      const result = await this.saveCompanyCourse(course)
      if (result) {
        this.closeEdition()
        this.title = ""
        this.$emit("refresh", result)
      }
    },

    async startNewSimulation() {
      this.title = i18n.t("view.companies.new_simulation")
      this.dialogNewSimulation = true
      const options = { timeZone: "Europe/Paris" }
      this.newSimulation = this.buildNewSimulation()
      this.$emit("refresh")
    },

    async editSimulation(simulationId) {
      this.title = i18n.t("view.companies.edit_a_simulation")
      this.dialogUpdateSimulation = true
      this.simulationToEdit = await this.getOneSimulation(simulationId)
      this.$emit("refresh")
    },

    async duplicate(data) {
      this.dialogNewSimulation = true
      this.title = i18n.t("view.companies.duplicate_a_simulation")
      const options = { timeZone: "Europe/Paris" }
      this.newSimulation = this.cloneSimulation(data)
      this.$emit("refresh")
    },

    async saveNewSimulation(data) {
      const result = await this.saveSimulation(data)
      if (result) {
        this.closeNewSimulation()
        this.title = ""
        this.$emit("refresh", result)
      }
    },

    closeNewSimulation() {
      this.newSimulation = null
      this.title = ""
    },

    async confirmDeleteSimulation(campaignId) {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_delete"),
          i18n.t("view.companies.confirm_delete_message"),
          i18n.t("Cancel")
        )
      ) {
        await this.suppressSimulation(campaignId)
        this.$emit("refresh")
      }
    },

    // create a new course
    async startNewCourse() {
      this.title = i18n.t("view.companies.new_learning")
      this.dialogNewCourse = true
      this.newCourse = this.getNewCompanyCourse()
    },

    // launch a course
    async startThisCompanyCourse(course) {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_launching"),
          i18n.t("view.companies.confirm_start_learning"),
          i18n.t("Cancel")
        )
      ) {
        await this.startCompanyCourse(course)
        this.$emit("refresh")
      }
    },

    // stop a course
    async stopThisCompanyCourse(course) {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_stop"),
          i18n.t("view.companies.confirm_stop_learning"),
          i18n.t("Cancel")
        )
      ) {
        await this.stopCompanyCourse(course)
        this.$emit("refresh")
      }
    },

    // launch a simulation
    async confirmLaunch(campaignId) {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_launching"),
          i18n.t("view.companies.confirm_launching_message"),
          i18n.t("Cancel")
        )
      ) {
        await this.launchSimulation(campaignId)
        this.$emit("refresh")
      }
    },

    // stopping a simulation
    async confirmStop(campaignId) {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_stop"),
          i18n.t("view.companies.confirm_stop_message"),
          i18n.t("Cancel")
        )
      ) {
        await this.stopSimulation(campaignId)
        this.$emit("refresh")
      }
    },

    async saveSimulationEdited(data) {
      const newData = await this.saveSimulation(data)
      if (newData) {
        this.closeEdition()
        this.title = ""
        this.$emit("refresh", newData)
      }
    },

    openPreview(data) {
      this.compiledTemplates = data
      this.showCompiledTemplates = true
    },

    closedPreview() {
      this.compiledTemplates = null
      this.showCompiledTemplates = false
    },

    async confirmCreateTest(simulationId) {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_test_simulation_creation"),
          i18n.t("view.companies.confirm_test_simulation_creation_message"),
          i18n.t("Cancel")
        )
      ) {
        await this.createTest(simulationId)
      }
    },

    async createTest(simulationId) {
      await this.createSimulationTest(simulationId)
      this.$emit("refresh")
    },

    save() {
      this.$emit("save", this.localData)
    },

    async suppress() {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_delete"),
          i18n.t("view.companies.confirm_delete_campaign_message"),
          i18n.t("Cancel")
        )
      ) {
        this.$emit("suppress", this.localData.id)
      }
    },

    generateReport() {
      this.dialog = true
    },

    cancel() {
      this.dialog = false
    },

    async confirm() {
      await this.$emit("generateReport", this.localData.id)
      this.dialog = false
    },

    languageSelected() {
      const selectedLanguage = this.language
      this.$emit("selection", selectedLanguage)
    },

    typeOfReportSelected() {
      const selectedTypeOfReport = this.typeOfReport
      this.$emit("selectionTypeOfReport", selectedTypeOfReport)
    },
  },
  watch: {
    showSimulationTest: function (newVal, oldVal) {
      this.showSimulationTest = newVal
    },

    editName: function () {
      if (!this.editName && this.localData.name !== this.campaign.name) {
        this.save()
      }
    },

    editDescription: function () {
      if (
        !this.editName &&
        this.localData.description !== this.campaign.description
      ) {
        this.save()
      }
    },
  },
}
</script>
<style scoped lang="scss">
.box-1 {
  display: inline-block;
  padding: 4px;
  width: 100%;
}

.formation-plus {
  position: relative;
  top: -3px;
  right: 4px;
  color: rgb(54, 52, 52);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: inherit;
  z-index: 1;
}

.simulation-plus {
  position: relative;
  top: -1px;
  right: 6px;
  color: rgb(54, 52, 52);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: inherit;
  z-index: 1;
}

.icon-container {
  display: flex;
}

.custom-dialog {
  max-width: 400px;
  z-index: 200;
}

.container {
  border: 0.1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
}
</style>

<template>
  <!-- display flex -->
  <v-row class="d-flex">
    <v-col class="ms-4">
      <div class="d-flex">
        <op-confirmation-dialog ref="confirm" />
        <div class="mt-6 me-8">
          <v-toolbar class="justify-space-between justify-center align-center">
            <v-btn
              @click="applyBold"
              icon
              :title="$t('view.custom_editor.text_in_bold')"
            >
              <v-icon>mdi-format-bold</v-icon>
            </v-btn>
            <v-btn
              @click="applyItalic"
              icon
              :title="$t('view.custom_editor.text_in_italic')"
            >
              <v-icon>mdi-format-italic</v-icon>
            </v-btn>
            <v-btn
              @click="applyParagraph"
              icon
              :title="$t('view.custom_editor.add_a_paragraph')"
            >
              <v-icon>mdi-format-paragraph</v-icon>
            </v-btn>
            <v-btn
              @click="applyH1"
              icon
              :title="$t('view.custom_editor.lvl_1_title')"
            >
              <v-icon>mdi-format-header-1</v-icon>
            </v-btn>
            <v-btn
              @click="applyListNumbers"
              icon
              :title="$t('view.custom_editor.numbered_bullet_list')"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn
              @click="applyList"
              icon
              :title="$t('view.custom_editor.bullet_list')"
            >
              <v-icon> mdi-view-list</v-icon>
            </v-btn>
            <span v-if="slide" class="d-flex">
              <v-btn
                @click="applyAnim('Left')"
                icon
                :title="$t('view.custom_editor.animation_from_left_to_right')"
              >
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
              <v-btn
                @click="applyAnim('Right')"
                icon
                :title="$t('view.custom_editor.animation_from_right_to_left')"
              >
                <v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
              <v-btn
                @click="applyAnim('Top')"
                icon
                :title="$t('view.custom_editor.animation_from_top_to_bottom')"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </span>
            <v-btn
              @click="applyStyleColor"
              icon
              :title="$t('view.custom_editor.red_color_text')"
            >
              <v-icon>mdi-format-color-fill</v-icon>
            </v-btn>
            <v-btn
              @click="applyListWithRadiusColor"
              icon
              :title="$t('view.custom_editor.coloured_round_beacon')"
            >
              <v-icon>mdi-transcribe</v-icon>
            </v-btn>
            <v-btn
              @click="applyDivWithIcon"
              icon
              :title="$t('view.custom_editor.add_a_div_with_icon')"
            >
              <v-icon>mdi-image-filter</v-icon>
            </v-btn>
            <v-btn
              @click="applyImg"
              icon
              :title="$t('view.custom_editor.add_an_image')"
            >
              <v-icon>mdi-image</v-icon>
            </v-btn>
            <v-btn
              @click="insertEmailTemplate"
              icon
              :title="$t('view.custom_editor.insert_an_email_template')"
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
            <v-btn
              @click="insertCrossWords"
              icon
              :title="$t('view.custom_editor.insert_a_crossword_game')"
              v-if="slide"
            >
              <v-icon>mdi-cube-unfolded</v-icon>
            </v-btn>
            <v-btn
              @click="insertDragAndDrop"
              icon
              :title="$t('view.custom_editor.insert_a_drag_and_drop_game')"
              v-if="slide"
            >
              <v-icon>mdi-gesture-two-tap</v-icon>
            </v-btn>
            <v-btn
              @click="insertScratching"
              icon
              :title="$t('view.custom_editor.insert_a_scratching_game')"
              v-if="slide"
            >
              <v-icon>mdi-texture</v-icon>
            </v-btn>
          </v-toolbar>
          <v-textarea
            v-model="editorContent"
            :rows="18"
            :placeholder="placeholderText"
            ref="editor"
            @input="updateContent"
          />
        </div>
      </div>
    </v-col>
    <v-col>
      <div class="w-100 pl-10">
        <h3 class="text-center">{{ $t("view.learning_phishing.preview") }}</h3>
        <div v-html="editorContent" class="ml-5 mt-10 text-center"></div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { i18n } from "@/plugins/i18n"

export default {
  data() {
    return {
      editorContent: this.value,
      placeholderText: i18n.t("view.custom_editor.enter_your_text_here"),
      lastCursorPos: 0,
      slide: true,
      description: true,
    }
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    isSlide: {
      type: Boolean,
      default: true,
    },
    isDescription: {
      type: Boolean,
      default: true,
    },
    selectedType: {
      default: null,
    },
  },
  mounted() {
    this.slide = this.isSlide
    this.description = this.isDescription
  },
  methods: {
    applyBold() {
      this.insertTag("<b>", "</b>")
    },
    applyItalic() {
      this.insertTag("<i>", "</i>")
    },
    applyH1() {
      this.insertTag("<h1>", "</h1>")
    },
    applyH2() {
      this.insertTag("<h2>", "</h2>")
    },
    applyList() {
      this.insertTag("<ul><li>", "</li></ul>")
    },
    applyAnim(direction) {
      this.insertTag(`class="anim anim${direction}`, '"')
    },
    applyStyleColor() {
      this.insertTag('style="color: red;', '"')
    },
    applyDivColors() {
      this.insertTag(
        '<ul style="list-style-type: none;"> <li style="padding: 10px; border-radius: 10px; margin-top: 10px; color: white; background: #050B7f;">',
        "</li></ul>"
      )
    },
    applyImg() {
      this.insertTag('<div align="center"> <img src="', '" /> </div>')
    },
    applyParagraph() {
      this.insertTag("<p>", "</p>")
    },
    applyListWithRadiusColor() {
      this.insertTag(
        '<ul style="list-style-type: none;"> <li style="padding: 10px; border-radius: 10px; margin-top: 10px; color: white; background: #050B7f;"> <---- Write text here -----> ',
        "</li></ul>"
      )
    },
    applyListNumbers() {
      this.insertTag("<ol><li>", "</li></ol>")
    },
    applyDivWithIcon() {
      this.insertTag(
        '<div style="display: flex; align-items: start;"> <img width="50" height="50" style="margin-right: 10px; margin-top: 10px;" src="',
        '" /><p> <---- Write text here -----> </p></div>'
      )
    },

    insertEmailTemplate() {
      const editor = this.$refs.editor.$el.querySelector("textarea")
      const currentValue = editor.value
      const newValue =
        currentValue +
        `<div class="email-base">
        <div class="flex items-center justify-between mt-4">
            <div class="flex items-center space-x-4">
                <div class="relative w-10 h-10">
                    <img class="rounded-full border border-gray-100 shadow-sm" src="/assets/oppens-logo.png" alt="" />
                    <div class="absolute bottom-0 right-0 h-3 w-3 my-1 border-2 border-white rounded-full bg-red-400 z-2"></div>
                </div>
                <div class="flex flex-col space-y-0">
                    <div class="font-semibold text-gray-800 dark:text-gray-200 sm:text-lg">De : XXXXXX</div>
                    <div class="text-sm text-gray-600 ">&Agrave; : !!!UserEmail!!!</div>
                    <div class="text-sm text-gray-600 ">Objet : XXXX</div>
                </div>
            </div>
            <div class="flex items-center space-x-2 text-gray-400">
                <div class="flex items-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span class="ml-2 text-xs">!!!DateTime!!!</span>
                </div>
            </div>
        </div>

        <div class="my-5">
            <div>
                <img width="300" height="auto" src="/assets/learning/Societe_Generale_logo.png"/>
                <br/>
                <p>Bonjour !!!UserName!!!,
                    <br/><br/>
                    Texte
                    <span class="tooltip-base underline text-blue-600">Lien à cliquer
                        <span class="tooltiptext-base text-xs">URL</span>
                    </span>
                    Texte

                    <br/>
                    <br/>
                    Cordialement,
                    <br/>
                    Signature
                </p>
            </div>
        </div>

    </div>`
      this.lastCursorPos = newValue.length
      editor.value = newValue
      editor.setSelectionRange(this.lastCursorPos, this.lastCursorPos)
      this.updateContent()
    },
    insertTag(openTag, closeTag) {
      const editor = this.$refs.editor.$el.querySelector("textarea")
      const startPos = editor.selectionStart
      const endPos = editor.selectionEnd
      const selectedText = editor.value.substring(startPos, endPos)

      const newText = `${openTag}${selectedText}${closeTag}`
      const currentValue = editor.value
      const newValue =
        currentValue.substring(0, startPos) +
        newText +
        currentValue.substring(endPos)

      this.lastCursorPos = startPos + openTag.length

      editor.value = newValue

      editor.setSelectionRange(this.lastCursorPos, this.lastCursorPos)

      this.updateContent()
    },
    updateContent() {
      const editor = this.$refs.editor.$el.querySelector("textarea")
      this.editorContent = editor.value
      this.$emit("input", editor.value)
    },
    insertCrossWords() {
      if (this.selectedType != 4 && this.selectedType != 8) {
        this.chooseType()
      }
      const editor = this.$refs.editor.$el.querySelector("textarea")
      let newValue = editor.value
      if (this.selectedType == 4) {
        if (this.description) {
          newValue += `<div class="container">
    <div class="crossword">
    </div>
</div>
<style>
    .crossword {
    margin: 1em auto;
    position: relative;
    font-family: sans-serif;
  }

  table.crossword-grid {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }

  .grid-row {
    clear: left;
  }

  .grid-square {
    position: relative;
    background-clip: padding-box;
    width: 20px;
    height: 20px;
  }

  .grid-square::after {
    content: "";
    display: block;
    margin-top: 100%;
  }

  .word-label {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    line-height: 1;
    font-size: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  }

  .crossword-grid input {
    border-collapse: collapse;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid rgb(211, 207, 207);
    150, 150, 0.5);
    padding-right: 2px;
    padding-left: 2px;
background: transparent;
  }
.grid-square.current-word {
  background-color: #1cc8ab10;
}
</style>`
        } else {
          newValue += `<div class="email-base" >  <div style="margin-left: 30px; margin-right: 30px;"><div class="mt-5">
            <strong style="color: #1cc8aa; font-family: Nunito, sans-serif;">VERTICAL</strong>
              <p class="mt-2">
                1- Elément attractif ou trompeur.
              </p>
              <p>
                2- Mesures prises pour  protéger les données
                et les systèmes contre les menaces.
              </p>
            </div>
            <div class="mt-5 mb-5">
                  <strong style="color: #1cc8aa;">HORIZONTAL</strong>

              <p class="mt-2">
                3- Personne qui commet des crimes en ligne.
              </p>
              <p>
                4-  Fraude en ligne pour voler des informations personnelles en se faisant passer pour une source de confiance.
              </p>

              <p>
                5-  Opération frauduleuse visant à tromper ou à escroquer.
              </p>
            </div></div></div>

<div id="myDiv" data-elem='[
  {
    "number": 1,
    "direction": "down",
    "row": 1,
    "column": 5,
    "answer": "Leurre"
  },
  {
    "number": 2,
    "direction": "down",
    "row": 3,
    "column": 12,
    "answer": "Securite"
  },
  {
    "number": 3,
    "direction": "across",
    "row": 4,
    "column": 1,
    "answer": "Cybercriminel"
  },
  {
    "number": 4,
    "direction": "across",
    "row": 8,
    "column": 10,
    "answer": "Phishing"
  },
  {
    "number": 5,
    "direction": "across",
    "row": 10,
    "column": 6,
    "answer": "Arnaque"
  }
]'data-elem2='[17, 10]'></div>

</div></div>`
        }
      }

      if (this.selectedType == 8) {
        if (this.description) {
          newValue += `<div class="container">
    <div class="crossword">
    </div>
</div>
<style>
   .crossword {
    margin: 1em auto;
    position: relative;
    font-family: sans-serif;
  }

  table.crossword-grid {
margin-top:20px;
    width: 100%;
    max-width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }

  .grid-row {
    clear: left;
  }

  .grid-square {
    position: relative;
    background-clip: padding-box;
    width: 20px;
    height: 20px;
  }

  .grid-square::after {
    content: "";
    display: block;
    margin-top: 100%;
  }

  .word-label {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    line-height: 1;
    font-size: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  }

  .crossword-grid input {
    border-collapse: collapse;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid rgb(211, 207, 207);
background: transparent;
  }
.grid-square.current-word {
  background-color: #1cc8ab10;
}
</style>`
        } else {
          newValue += `<div class="email-base" >  <div style="margin-left: 30px; margin-right: 30px;"><div class="mt-5">
            <strong style="color: #1cc8aa; font-family: Nunito, sans-serif;">VERTICAL</strong>
              <p class="mt-2">
                1-  Expression d'intention ou d'action nuisible, pouvant causer des dommages.
              </p>
              <p>
                2-Expression d'intention ou d'action nuisible, pouvant causer des dommages.
              </p>
            </div>
            <div class="mt-5 mb-5">
                  <strong style="color: #1cc8aa;">HORIZONTAL</strong>

              <p class="mt-2">
                3- Malhonnêteté ou l'illégalité dans le but de tromper ou d'obtenir des avantages indus.
              </p>
              <p>
                4-  Fraude en ligne pour voler des informations personnelles en se faisant passer pour une source de confiance.
              </p>


            </div></div></div>

<div id="myDiv" data-elem='[
  {
    "number": 1,
    "direction": "down",
    "row": 1,
    "column": 4,
    "answer": "Email"
  },
  {
    "number": 2,
    "direction": "down",
    "row": 2,
    "column": 8,
    "answer": "Menaces"
  },
  {
    "number": 3,
    "direction": "across",
    "row": 4,
    "column": 2,
    "answer": "Phishing"
  },
  {
    "number": 4,
    "direction": "across",
    "row": 7,
    "column":1 ,
    "answer": "Frauduleux"
  }

]'data-elem2='[10, 10]'></div>

</div></div>`
        }
      }
      this.lastCursorPos = newValue.length
      editor.value = newValue
      editor.setSelectionRange(this.lastCursorPos, this.lastCursorPos)
      this.updateContent()
    },
    insertDragAndDrop() {
      if (this.selectedType != 6 && this.selectedType != 7) {
        this.chooseType()
      }
      const editor = this.$refs.editor.$el.querySelector("textarea")
      const currentValue = editor.value
      let newValue = currentValue
      if (this.selectedType == 6) {
        if (this.description) {
          newValue += `
<p>Bienvenue dans le jeu "Trier les Bonnes et Mauvaises Pratiques" ! Votre mission : glisser-déposer les pratiques de sécurité en ligne dans les catégories "Bonnes pratiques à suivre" ou "Mauvaises pratiques à éviter".  Examinez attentivement chaque pratique, utilisez votre intuition et devenez un expert de la sécurité en ligne. Prêt à trier et à apprendre les meilleures pratiques ? Commencez maintenant !</p>
`
        } else {
          newValue += `<style>
  .big-container {
    display: flex;
     flex-direction: column;
    align-items: center;
  border: 1px solid  #c4c4c5 ;
    box-shadow: 0 4px 8px #888888;
 border-radius: 10px; 
 max-width: 60rem;
  width: 100%;
  margin: auto;
  }

  .container-a,
  .container-b,
  .container-c {
    margin: 0.625rem;
    width: 18.75rem;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    min-width: 6.25rem;
    margin-top: 0.625rem;
text-align: center;
align-items: center;


  }

  .container-a,
  .container-b {
    border-right: 0.125rem solid #1cc8aa;
  }


  .box {
    width: 100%;
    max-width: 15.625rem;
  }

  .draggableWord {
    position: relative;
    background-color: #b0b0b0;
    color: white;
    padding: 0.625rem;
    margin-top: 0.3125rem;
    margin-bottom: 0.625rem;
    font-size: 0.75rem;
    border: 0.0625rem solid #b0b0b0;
    cursor: pointer;
    word-wrap: break-word;
    margin-left: -0.5rem;
  }
.container-a .heading,
.container-c .heading {
  text-align: center; 
align-items: center;

}
  .heading {
    font-size: 0.875rem;
    text-align: center;
    color: #1cc8aa;
    margin-bottom: 1.25rem;
    word-wrap: break-word;
margin-left: -1.1rem;
  }

  #list,
  #toDo,
  #notToDo {
    height: 21.875rem;
    overflow: hidden;
  }

  @media (min-width: 48rem) {
    .big-container {
      flex-direction: row;
      align-items: flex-start;
    }

    .draggableWord.is-dragging {
      background-color: #c4c4c5 !important;
      border: 0.0625rem solid #c4c4c5;
      color: white !important;
    }
  }
</style>




<div class="big-container">
  <div class="container-b">
    <div class="box" id="list">
      <h3 class="heading">List à trier</h3>
      <div class="draggableWord" draggable="true">Vérifier l'URL du site</div>
      <div class="draggableWord" draggable="true">Cliquer sur des liens suspects</div>
      <div class="draggableWord" draggable="true">Ouvrir les pièces jointes non sollicitées</div>
      <div class="draggableWord" draggable="true">Mettre à jour régulièrement les logiciels</div>
      <div class="draggableWord" draggable="true">Cliquer sur des publicités douteuses</div>
      <div class="draggableWord" draggable="true">Examiner les e-mails avec prudence</div>
    </div>
  </div>

  <div class="container-a">
      <div class="box" id="toDo">
        <h3 class="heading">Bonnes pratiques </h3>
      </div>
    </div>

  <div class="container-c">
      <div class="box" id="notToDo">
        <h3 class="heading">Mauvaise pratique </h3>
      </div>
  </div>
</div>


<div
  id="myElement"
  data-elem='{"listA": ["Vérifier lʼURL du site", "Mettre à jour régulièrement les logiciels", "Examiner les e-mails avec prudence"], "listB": ["Cliquer sur des liens suspects", "Ouvrir les pièces jointes non sollicitées", "Cliquer sur des publicités douteuses"]}'
></div>
`
        }
      }
      if (this.selectedType == 7) {
        if (this.description) {
          newValue += ` <div class="big-container-titre">
  <div class="container-drag-titre">
<div class="box-list">
<div class="draggableWord" draggable="true" >
        <p>Phishing</p>
      </div>
      <div class="draggableWord" draggable="true" >
        <p>Malwares</p>
      </div>

      <div class="draggableWord" draggable="true" >
        <p>Sécurité</p>
      </div>
      <div class="draggableWord" draggable="true" >
        <p>Sauvegarde</p>
      </div>
    </div>
</div>

</div>
<style>
.container-drag-titre{
  margin-top: 40px;
  width: fit-content;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.big-container-titre {
  display: flex;
flex-wrap:nowrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.box {
  border: 1px solid rgb(86, 139, 139);
  padding: 50px;
  border-radius: 5px;
  width: 250px;
  margin-right: 10px;
  margin-bottom: 10px;
}


.draggableWord {
  position: relative;
  background-color: rgb(86, 139, 139);
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid rgb(86, 139, 139);
  cursor: pointer;
  text-align:center;
}

.box-list {
  padding: 15px;
  border-radius: 5px;
  width: 250px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.is-dragging {
  background-color: rgb(86, 85, 138);
  color: white;
}
</style>
`
        } else {
          newValue += `<div class="container-list">
<div class="box" id="1">

            <h3 class="heading">Fraude par courrier électronique.</h3>
          </div>



          <div class="box" id="2">

            <h3 class="heading">Logiciels nuisibles et malveillants</h3>
          </div>
 <div class="box" id="3">
            <h3 class="heading">Protection de données cruciales</h3>
          </div>

 <div class="box" id="4">

            <h3 class="heading">Prévention des risques informatiques</h3>
          </div>
</div>
<div id="myElement" data-elem='{"listA": ["Phishing","Malwares","Sauvegarde","Sécurité"]}'></div>
<style>
.container-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: -10px;

}
</style>

`
        }
      }
      this.lastCursorPos = newValue.length
      editor.value = newValue
      editor.setSelectionRange(this.lastCursorPos, this.lastCursorPos)
      this.updateContent()
    },

    insertScratching() {
      if (this.selectedType != 3 && this.selectedType != 5) {
        this.chooseType()
      }
      const editor = this.$refs.editor.$el.querySelector("textarea")
      const currentValue = editor.value
      let newValue = currentValue
      if (this.selectedType == 3) {
        if (this.description) {
          newValue += `<p>Pendant vos vacances, votre collègue chargé de reprendre votre travail se trouve dans l'incapacité d'accéder à son compte d'entreprise pour consulter les dossiers clients. Il vous sollicite en vous demandant de lui transmettre votre identifiant et votre mot de passe, le temps de contacter le support technique. Que choisissez-vous de faire ?<br><br>
A - Oui, vous les lui envoyez pour qu'il puisse continuer son travail.<br><br>
B - Non, vous refusez et lui recommandez d'attendre l'intervention du support technique."</p><br>
<p><span>Grattez</span> la bonne réponse</p>
`
        } else {
          newValue += `<div style="width: 31em; height: 31em; background-color: #f5f5f5; position: relative; border-radius: 0.6em;">
    <div style="display: flex; ">
    <div style="margin:20% 25px;">
        <canvas id="scratch" width="200" height="200"
        style="position: absolute;  cursor: grabbing; border-radius: 0.3em;"></canvas>
      <img class="base" src="../../assets/learning-animation/wrong.gif"
      style="height: 200px; width: 200px;cursor: grabbing; border-radius: 0.3em; background-color: #ffffff;  align-items: center; box-shadow: 0 1.2em 2.5em rgba(16, 2, 96, 0.15);">
        <h1 style="text-align: center;"><b>A</b></h1>
    </div>
    <div style="margin:20% 25px;">
        <canvas id="scratch2" width="200" height="200"
          style="position: absolute;  cursor: grabbing; border-radius: 0.3em;"></canvas>
      <img class="base" src="https://csg-storage-oppens.s3.eu-west-3.amazonaws.com/_store/logo/oppens_logo.png"
      style="height: 200px; width: 200px;cursor: grabbing; border-radius: 0.3em; background-color: #ffffff;  align-items: center; box-shadow: 0 1.2em 2.5em rgba(16, 2, 96, 0.15)">
      <h1 style="text-align: center;"><b>B</b></h1>
    </div>
  </div>
  </div>
</div>
<div><div><div><div><div><div><div>  <div id="myDiv" data-elem='{"color1": "#10c1a3", "color2": "#62dbc6"}'></div>
</div></div></div></div></div></div></div>
`
        }
      }
      if (this.selectedType == 5) {
        if (this.description) {
          newValue += `<p>Un simple câble de recharge pour smartphone, en apparence anodin, peut dissimuler un logiciel malveillant capable de voler vos données. Soyez prudent et n'utilisez que des câbles de confiance.</p><br>
<p>Grattez la partie droite pour dévoiler la <span>menace</span></p>`
        } else {
          newValue += `<div style="display: flex;flex-direction:column ">
  <!-- Premier ensemble -->
  <div style="width: 31em; height: 31em; background-color: #f5f5f5; position: relative; border-radius: 0.6em;">
    <img class="base" src="../../assets/learning-animation/cable-malwares.png"
      style="height: 400px; width: 400px; position: absolute; transform: translate(-50%, -50%); top: 50%; left: 50%; text-align: center; cursor: grabbing; border-radius: 0.3em; background-color: #ffffff; font-family: 'Poppins', sans-serif; display: flex; flex-direction: column; align-items: center; justify-content: center; box-shadow: 0 1.2em 2.5em rgba(16, 2, 96, 0.15);">
    <canvas id="scratch5" width="400" height="400"
      style="position: absolute; transform: translate(-50%, -50%); top: 50%; left: 50%; text-align: center; cursor: grabbing; border-radius: 0.3em;"></canvas>
  </div>
</div>
<div><div><div><div><div><div><div>  <div id="myDiv" data-elem='{"color1": "#5CD2E6", "color2": "#5CD2E6"}'></div>
</div></div></div></div></div></div></div>`
        }
      }
      this.lastCursorPos = newValue.length
      editor.value = newValue
      editor.setSelectionRange(this.lastCursorPos, this.lastCursorPos)
      this.updateContent()
    },

    async chooseType() {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.custom_editor.choice_of_type"),
          i18n.t("view.custom_editor.please_select_a_valid_type"),
          i18n.t("view.custom_editor.modify")
        )
      ) {
        console.log("ok")
      }
    },
  },
}
</script>

<style scoped>
.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.pl-10 {
  padding-left: 10px;
}

.bg-branding-gradient {
  background-image: linear-gradient(
    to right,
    var(--branding-gradient-from),
    var(--branding-gradient-middle),
    var(--branding-gradient-to)
  );
}

.primary-border {
  border-color: var(--primary-color);
}

[type="checkbox"]:checked {
  color: var(--primary-color);
}

[type="radio"]:checked {
  color: var(--primary-color);
}

.feedback span {
  color: var(--primary-color);
}

.feedback_long span {
  color: var(--primary-color);
}

span {
  color: v-bind(customPrimaryColor);
}

p {
  margin-top: 10px;
  margin-bottom: 15px;
  color: var(--v-steel-base) !important;
}

ul {
  list-style-position: inside;
  list-style-type: disc;
  line-height: 1, 5;
  color: var(--v-steel-base) !important;
}

li {
  margin-bottom: 5px;
}

.ul-quizz li {
  list-style-type: none;
}

.source-base {
  padding-top: 20px;
  font-size: 0.8rem !important;
  font-style: italic;
}

.email-base {
  font-family: Nunito, sans-serif;
}

.tooltip-base {
  position: relative;
  display: inline-block;
}

.tooltip-base .tooltiptext-base {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-base .tooltiptext-base::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-base:hover .tooltiptext-base {
  visibility: visible;
  opacity: 1;
}
</style>

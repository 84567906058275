import Request from "../services/Request"

export default class UserRepository {
  static getAllNonAnonymousUsers(params) {
    return Request.make("GET", "/admin/users", { params })
  }

  static getUserRoles() {
    return Request.make("GET", "/user-roles")
  }
}

<template>
  <div v-if="localData">
    <v-card elevation="0" outlined color="white" class="ma-1">
      <v-card-text>
        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{ $t("view.learning_phishing.choice_of_hat_course") }}
          </h3>
          <v-select
            v-model="localData.hat_course_id"
            :items="hatCourses"
            item-text="title"
            item-value="id"
            :label="$t('view.learning_phishing.choice_of_hat_course')"
          ></v-select>
        </v-row>
        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.title") }}</h3>
          <v-text-field
            :label="$t('view.learning_phishing.title')"
            v-model="localData.title"
            class="required"
          >
          </v-text-field>
        </v-row>
        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">Info clé</h3>
          <v-text-field
            label="Info clé"
            v-model="localData.subtitle"
          >
          </v-text-field>
        </v-row>
        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">Description</h3>
          <v-textarea
            :label="$t('view.learning_phishing.description')"
            v-model="localData.description"
            class="required"
          >
          </v-textarea>
        </v-row>
        <v-row>
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.language") }}</h3>
          <v-select
            :label="$t('view.learning_phishing.select_a_language')"
            :items="languages"
            v-model="localData.lang"
            dense
          ></v-select>
        </v-row>

        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{$t("view.learning_phishing.choice_of_slides")}}
          </h3>
        </v-row>

        <v-data-table
          v-model="selectedSlides"
          :headers="headers"
          :items="slides"
          :single-select="false"
          :items-per-page="10"
          item-key="id"
          show-select
          class="elevation-1"
          :search="search"
          :footer-props="footerProps"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" small
          >{{ $t("view.button.save") }}</v-btn
        >
        <v-btn elevation="2" @click="close()" small>{{ $t("view.button.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import oppensLearningMixin from "@/mixins/oppens.learning.mixin.js"
import slidesTableMixin from "@/mixins/slidesTable.mixin"
import ConfigLogic from "@/logics/ConfigLogic";

export default {
  mixins: [oppensLearningMixin, slidesTableMixin],
  name: "oppens-learning-course-edition",
  props: {
    data: {
      default: null,
    },
    slides: {
      default: null,
    },
    hatCourses: {
      default: null,
    },
  },

  data: function () {
    return {
      languages: [],
      selectedSlides: [],
      localData: null,
    }
  },

  async mounted() {
    this.languages = await ConfigLogic.listLanguages()
    this.localData = JSON.parse(JSON.stringify(this.data))
    this.selectedSlides = []
    for (let slide of this.localData.slides) {
      const fullSlide = this.slides.find((element) => element.id === slide.id)
      if (fullSlide) {
        this.selectedSlides.push(fullSlide)
      }
    }
  },
  computed: {
    form() {
      return this.$refs.form
    },
  },
  methods: {
    async submit() {
      this.localData.slides = this.selectedSlides.map((elt) => elt.id)
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>

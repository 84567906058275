<template>
  <div v-if="data">
    <v-card elevation="0" outlined class="ma-1">
      <v-card-title>{{ data.name }}</v-card-title>
      <v-card-subtitle>
        <div>{{ $t("view.learning_phishing.redirect_url") }} : {{ data.redirect_url }}</div>
        <div>{{ $t("view.learning_phishing.url_to_server") }} : {{ data.url }}</div>
      </v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" small @click="duplicate()">{{ $t("view.button.duplicate") }}</v-btn>
        <v-btn elevation="2" small @click="edit()">{{ $t("view.button.edit") }}</v-btn>
        <v-btn elevation="2" small dark color="green" @click="viewCode()"
        >{{ $t("view.button.show") }}
        </v-btn
        >
        <v-icon @click="suppress()" class="ml-2" elevation="2" icon
        >mdi-delete
        </v-icon
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "phishing-landing-page",
  data: () => ({
    reveal: false,
  }),
  props: {
    data: {
      default: null,
    },
  },
  methods: {
    viewCode() {
      this.$emit("view", this.data.html)
    },
    async suppress() {
      this.$emit("suppress", this.data)
    },
    edit() {
      this.$emit("edit", this.data)
    },
    duplicate() {
      this.$emit("duplicate", this.data)
    },
  },
}
</script>

<template>
  <v-app class="mx-auto app">
    <!--  mini-variant expand-on-hover-->
    <v-navigation-drawer app permanent>
      <v-list nav dense>
        <div v-for="(link, i) in links" :key="i">
          <v-list-item
            v-if="!link.subLinks"
            :to="link.route"
            :active-class="color"
            class="v-list-item"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else
            :key="link.title"
            no-action
            :prepend-icon="link.icon"
            :value="false"
            class="black--text"
          >
            <template v-slot:activator>
              <v-list-item-title class="black--text"
                >{{ link.title }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="sublink in link.subLinks"
              :to="sublink.route"
              :key="sublink.title"
              :active-class="color"
            >
              <v-list-item-title class="black--text"
                >{{ sublink.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <transition name="fade">
        <router-view :permissions="roles"></router-view>
      </transition>
    </v-main>

    <v-footer :padless="padless">
      <v-card flat tile width="100%" class="grey lighten-1 text-center">
        <v-divider></v-divider>
        <v-card-text class="black--text">
          {{ new Date().getFullYear() }} — <strong>Oppens Admin</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import AuthLogic from "@/logics/AuthLogic"
import AuthStore from "@/stores/AuthStore"

export default {
  data: () => ({
    model: null,
    drawer: true,
    mini: false,
    color: "#ccc",
    items: ["default", "absolute", "fixed"],
    padless: false,
    variant: "default",
    roles: null,
    isSystemAdmin: false,
    isLearningSystemAdmin: false,
    isPhishingSystemAdmin: false,
    isAccessControlSystemAdmin: false,
  }),

  methods: {
  },

  async mounted() {
    this.isSystemAdmin = await AuthStore.hasSystemRole("system-admin")
    this.isLearningSystemAdmin = await AuthStore.hasSystemRole("learning-system-admin")
    this.isPhishingSystemAdmin = await AuthStore.hasSystemRole("phishing-system-admin")
    this.isAccessControlSystemAdmin = await AuthStore.hasSystemRole("access-control-system-admin")
  },

  computed: {
    user() {
      return this.$authStore.state.me
    },

    links() {
      const links = [
        {
          title: this.$i18n.t("navigation.dashboard"),
          icon: "mdi-home-outline",
          route: {
            name: "dashboard",
          },
        },

        {
          title: this.$i18n.t("navigation.companies"),
          icon: "mdi-domain",
          route: {
            name: "companies",
          },
        },
        {
          title: this.$i18n.t("navigation.users"),
          icon: "mdi-account-group",
          route: {
            name: "users",
          },
        },

        {
          title: this.$i18n.t("navigation.access-control"),
          icon: "mdi-security",
          canView: this.isAccessControlSystemAdmin,
          route: {
            name: "access-control-perimeters",
          },
        },
        {
          title: this.$i18n.t("navigation.news"),
          icon: "mdi-newspaper",
          canView: this.isLearningSystemAdmin,
          route: {
            name: "news",
          },
        },
        {
          title: this.$i18n.t("navigation.learning.learning"),
          icon: "mdi-desktop-classic",
          canView: this.isLearningSystemAdmin,
          subLinks: [
            {
              title: this.$i18n.t("navigation.learning.catalogs"),
              route: {
                name: "hat-course-catalogs",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.assets"),
              route: {
                name: "oppens-learning-assets",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.hatCourses"),
              route: {
                name: "oppens-learning-hat-courses",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.courses"),
              route: {
                name: "oppens-learning-courses",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.answers"),
              route: {
                name: "oppens-learning-answers",
              },
            },
            {
              title: this.$i18n.t("navigation.learning.slides"),
              route: {
                name: "oppens-learning-slides",
              },
            },
          ],
        },
        {
          title: this.$i18n.t("navigation.phishing.phishing"),
          icon: "mdi-shield-half-full",
          canView: this.isPhishingSystemAdmin,
          subLinks: [
            {
              title: this.$i18n.t("navigation.phishing.catalogs"),
              route: {
                name: "phishing-exercise-catalogs",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.assets"),
              route: {
                name: "phishing-assets",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.exercises"),
              route: {
                name: "phishing-exercises",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.emailTemplates"),
              route: {
                name: "phishing-email-templates",
              },
            },

            {
              title: this.$i18n.t("navigation.phishing.landingPages"),
              route: {
                name: "phishing-landing-pages",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.servers"),
              route: {
                name: "phishing-servers",
              },
            },
            {
              title: this.$i18n.t("navigation.phishing.sendingProfiles"),
              route: {
                name: "phishing-sending-profiles",
              },
            },
          ],
        },
        {
          title: this.$i18n.t("navigation.system.system"),
          icon: "mdi-chart-bar-stacked",
          canView: this.isSystemAdmin,
          subLinks: [
            {
              title: this.$i18n.t("navigation.system.monitoring"),
              route: {
                name: "system-monitoring",
              },
            },

            {
              title: "Uptime servers",
              route: {
                name: "uptime-servers",
              },
            },

            {
              title: this.$i18n.t("navigation.system.notifications"),
              route: {
                name: "system-notifications-templates",
              },
            },
            {
              title: this.$i18n.t("navigation.system.emailHeadersFooters"),
              route: {
                name: "system-headers-footers-templates",
              },
            },
          ],
        },
        {
          title: this.$i18n.t("navigation.logout"),
          icon: "mdi-power",
          route: {
            name: "logout",
          },
        },
      ]

      return links.filter((link) => {
        if (Object.prototype.hasOwnProperty.call(link, "canView")) {
          return link.canView
        }
        // If the link doesn't have a canView property, always show it
        return true
      })
    },
  },
}
</script>

<style scoped>
::v-deep .v-list-item--active .mdi:before {
  color: #1cc8aa !important;
}

::v-deep .v-list-item--link:before {
  background-color: transparent;
}
</style>

<style>
.app {
  height: 100%;
  width: 100%;
}

.container {
  background: rgb(243 243 243);
}

.page-title {
  font-size: 0.9rem;
  color: #505050;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.required label::after {
  content: "*";
  color: red;
}
</style>
